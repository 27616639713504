<template>
    <div class="step-setup">
        <div class="container">
            <div>
                <SlideUpload class="mb-8" ref="uploader" :videoAllowed="false" :maxFiles="1"
                    @filesAdded="file => onFileAdded(file)" :disabled="state.isFamily">
                    <template #activator="{ onClick }">
                        <div class="d-flex flex-column align-center">
                            <div @click="onClick"
                                :class="['profile-wrapper pointer', state.isFamily ? 'disabled' : '']">
                                <div class="pending-slide" v-if="pendingUpload">
                                    <ImagePreview :media="pendingUpload" alt="Profile Photo" />
                                    <div class="progress-indicator">
                                        <div class="progress-slider"
                                            :style="{ width: `${pendingUpload.progress.percentage}%` }"></div>
                                    </div>
                                </div>

                                <div v-else-if="profileImageUrl" class="image-preview">
                                    <!-- class="slide-image" -->

                                    <ImageThumbnail class="image-thumbnail" :src="profileImageUrl" :type="0" :h="500"
                                        :w="500" />

                                    <button @click.stop="removeImage" class="delete-image">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.833 5.833L15.111 15.952c-.062.872-.788 1.548-1.662 1.548H6.552c-.875 0-1.601-.676-1.663-1.548L4.167 5.833M8.333 9.167v5M11.667 9.167v5M12.5 5.833V3.333c0-.46-.373-.833-.833-.833H8.333c-.46 0-.833.373-.833.833v2.5M3.333 5.833h13.333"
                                                stroke="#EF4444" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </div>

                                <div v-else class="profile-place-holder">
                                    <font-awesome-icon icon="fa-solid fa-user"
                                        style="font-size: 2.5rem"></font-awesome-icon>
                                </div>
                            </div>
                        </div>
                    </template>
                </SlideUpload>

                <template v-if="!state.isContributor && !state.isFamily">
                    <h5 class="font-weight-bold">Upload {{ firstName }}'s Image</h5>
                    <p>Upload a photo of your loved one to serve as the thumbnail for this tribute.</p>
                    <div class="d-flex" style="gap: 12px">
                        <button @click="openFileExplorer" for="image" class="secondary-button">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.667 10.667v.666c0 1.105.896 2 2 2h6.667c1.105 0 2-.895 2-2v-.666M10.667 5.333L8 2.667M8 2.667L5.333 5.333M8 2.667v8"
                                    stroke="#6B7280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span> {{ profileImageUrl ? 'Re-Upload' : 'Upload' }}</span>
                        </button>
                        <button v-if="!pendingUpload" @click="removeImage" class="danger-button">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.833 5.833L15.111 15.952c-.062.872-.788 1.548-1.662 1.548H6.552c-.875 0-1.601-.676-1.663-1.548L4.167 5.833M8.333 9.167v5M11.667 9.167v5M12.5 5.833V3.333c0-.46-.373-.833-.833-.833H8.333c-.46 0-.833.373-.833.833v2.5M3.333 5.833h13.333"
                                    stroke="#EF4444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>
                </template>
                <template v-else>
                    <h5 class="font-weight-bold text-center">{{ fullName }}'s Tribute Video</h5>
                    <p class="mb-0" v-if="(state.isContributor || state.isFamily) && !contributorDetailsSet">To continue please state your name and relationship to {{ firstName }}.</p>
                </template>
            </div>
            <div class="deadline-container mt-4">
                <template v-if="!state.isContributor && !state.isFamily">
                    <h5 class="font-weight-bold">Set a Deadline for Contributions</h5>
                    <p>Set a deadline for contributors to submit photos and videos for the tribute.</p>
                    <div class="select-wrapper">
                        <calendar datetime v-model="deadline.date" @save="updateDeadline" top ref="deadlinePicker"
                            label="Choose Date and Time" outlined clearable @click:clear="clearDeadline"/>
                    </div>
                </template>
                <template v-else-if="state.isContributor || state.isFamily">
                    <template v-if="!contributorDetailsSet">
                        <div class="mb-3 field">
                            <h5 class="font-weight-bold">Your name</h5>
                            <v-text-field v-model="contributor.name" outlined dense></v-text-field>
                        </div>
                        <div class="mb-3 field">
                            <h5 class="font-weight-bold">Relationship</h5>
                            <v-select outlined dense v-model="contributor.relationship"
                                :items="['Acquaintance', 'Relative', 'Friend', 'Family']"></v-select>
                        </div>
                    </template>
                    <template v-else>
                        <div class="font-weight-medium text-center" style="align-self: center;">
                            Welcome back, {{ contributorFirstName }}. 
                            <v-btn outlined small @click="() => contributorDetailsSet = false">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.8898 3.11019L17.5969 2.40309V2.40309L16.8898 3.11019ZM5.41673 17.5296V18.5296C5.68194 18.5296 5.9363 18.4242 6.12383 18.2367L5.41673 17.5296ZM2.50006 17.5296H1.50006C1.50006 18.0819 1.94778 18.5296 2.50006 18.5296V17.5296ZM2.50006 14.5537L1.79295 13.8466C1.60542 14.0341 1.50006 14.2885 1.50006 14.5537H2.50006ZM14.6507 3.8173C15.0737 3.39423 15.7596 3.39423 16.1827 3.8173L17.5969 2.40309C16.3928 1.19897 14.4406 1.19897 13.2364 2.40309L14.6507 3.8173ZM16.1827 3.8173C16.6058 4.24037 16.6058 4.9263 16.1827 5.34937L17.5969 6.76358C18.801 5.55946 18.801 3.6072 17.5969 2.40309L16.1827 3.8173ZM16.1827 5.34937L4.70962 16.8225L6.12383 18.2367L17.5969 6.76358L16.1827 5.34937ZM5.41673 16.5296H2.50006V18.5296H5.41673V16.5296ZM13.2364 2.40309L1.79295 13.8466L3.20717 15.2608L14.6507 3.8173L13.2364 2.40309ZM1.50006 14.5537V17.5296H3.50006V14.5537H1.50006ZM11.9864 5.0673L14.9327 8.01358L16.3469 6.59937L13.4007 3.65309L11.9864 5.0673Z"
                                        fill="#6B7280" />
                                </svg>
                            </v-btn>
                        </div>
                    </template>
                </template>
                <div class="mt-3" v-if="tributeVideo.deadline && (state.isContributor || state.isFamily)">
                    <h5 class="font-weight-bold">Upload deadline</h5>
                    <p class="deadline">
                        <template v-if="!deadlineExpired">
                            All photos must be uploaded by, {{ deadline.date | moment('LLL') }}.
                        </template>
                        <template v-else>
                            The deadline to upload media has already passed.
                        </template>
                    </p>
                </div>
            </div>
            <template v-if="state.isContributor && tributeVideoExists">
                <button :disabled="loading || conditionallyRequired" @click="handleClickNext"
                    class="primary-button">
                    <span class="button-text">Next</span>
                </button>
            </template>
            <template v-else>
                <button v-if="tributeVideoExists" :disabled="loading" @click="handleClickNext" class="primary-button">
                    <span class="button-text">Next</span>
                </button>
                <button v-else :disabled="loading" @click="createTributeVideo" class="primary-button">
                    <span class="button-text">Create Tribute Video</span>
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import Calendar from '@/components/ui/Calendar.vue';
import SlideUpload from '@/components/Tribute/SlideUpload.vue';
import { mapActions, mapGetters } from 'vuex';
import ImageThumbnail from '../Media/ImageThumbnail.vue';
import ImagePreview from '../Media/ImagePreview.vue';
import moment from 'moment';
import { isEmpty } from 'lodash';
export default {
    name: 'TributeSetup',
    data() {
        return {
            deadline: {
                date: null,
                time: null,
            },
            image: {
                previewURL: null,
                file: null,
            },
            loading: false,
            token: null,
            apiService: null,
            pendingUpload: null,
            tempFiles: [],
            contributor: {
                name: null,
                relationship: null
            },
            contributorDetailsSet: false
        };
    },
    inject: ['state'],
    props: {
        serviceSlug: {
            type: String,
            required: true,
        },
        tributeVideo: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        calendar: Calendar,
        SlideUpload,
        ImageThumbnail,
        ImagePreview,
    },
    computed: {
        tributeVideoExists() {
            if (!this.tributeVideo || !this.tributeVideo.id) return false;

            return this.tributeVideo.id > 0;
        },
        fullName() {
            if (this.tributeVideo?.firstName && this.tributeVideo?.lastName) {
                return `${this.tributeVideo?.firstName} ${this.tributeVideo?.lastName}`;
            }
            return this.firstName;
        },
        firstName() {
            return this.tributeVideo?.firstName || 'Honoree';
        },
        profileImageUrl() {
            return this.tributeVideo?.mainPhotoUrl;
        },
        ...mapGetters('tributeVideo', ['deadlineExpired']),
        conditionallyRequired() {
            // return true to disable fields
            // disable next until fields are set
            if (this.state.isContributor || this.state.isFamily) {
                if (!this.contributor.name || !this.contributor.name.length) {
                    return true;
                }
                if (!this.contributor.relationship || !this.contributor.relationship.length) {
                    return true;
                }
            }
            return false;
        },
        contributorFirstName() {
            if (this.contributor?.name?.length > 0) {
                const words = this.contributor.name.split(' ');
                return words[0];
            }
            return '';
        }
    },

    methods: {
        ...mapActions(['showSnackbar', 'block']),
        openFileExplorer() {
            if (!this.$refs.uploader || typeof this.$refs.uploader.openFileSelection() !== 'function') {
                console.log('error opening file explorer');
                return;
            }

            this.$refs.uploader.openFileExplorer();
        },
        async onFileAdded(file) {
            if (this.tributeVideoExists && this.tributeVideo.eventId) {
                await this.handleUpdateProfileImage(file);
            } else {
                if (file) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.$store.dispatch('tributeVideo/updateTributeVideo', {
                            ...this.tributeVideo,
                            mainPhotoUrl: e.target.result,
                        });
                    };

                    let fileData = file.data ? file.data : file;
                    reader.readAsDataURL(fileData);
                    this.image.file = fileData;
                } else {
                    this.removeImage();
                }
            }
        },
        async handleUpdateProfileImage(file) {
            this.pendingUpload = file;
            const uploadResp = await this.uploadPhoto(this.tributeVideo.eventId, true);
            this.pendingUpload = null;

            if (uploadResp.data.length === 0) {
                this.showSnackbar({ message: 'Error updating profile', color: 'error' });
                return;
            }

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                mainPhotoUrl: uploadResp.data[0].url,
                mainPhotoId: uploadResp.data[0].id,
            });

            this.showSnackbar({ message: 'Profile picture updated' });
        },
        uploadPhoto(eventId, replaceMainPhoto = false) {
            if (!eventId || eventId <= 0) {
                throw new Error('Invalid event id');
            }

            const uploader = this.$refs.uploader;

            if (!uploader || typeof uploader.initUpload !== 'function') {
                throw new Error('Error initiating upload');
            }

            return uploader.initUpload(eventId, replaceMainPhoto);
        },
        async removeImage() {
            // this.image = {
            //     previewURL: null,
            //     file: null,
            // };

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                mainPhotoId: 0,
                mainPhotoUrl: null,
            });

            const uploader = this.$refs.uploader;
            if (uploader && typeof uploader.reset === 'function') {
                uploader.reset();
            }

            if (this.tributeVideo?.mainPhotoId) {
                await this.apiService.tributePhoto.deletePhoto(this.tributeVideo?.mainPhotoId);
                this.showSnackbar({ message: 'Profile picture deleted' });
            }
        },
        async clearDeadline() {
            this.deadline.date = null;
            this.deadline.time = null;

            this.$refs.deadlinePicker.handleFocusOut();
            await this.$nextTick();

            this.updateDeadline();
            
        },
        async updateDeadline() {
            if (this.deadline.date) {
                const data = {
                    deadline: this.deadline.date,
                };

                if (this.tributeVideo?.id) {
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        ...this.tributeVideo,
                        deadline: data.deadline,
                    });
                    await this.apiService.tributeVideo.updateDeadline(this.tributeVideo.id, data);
                    
                }
            } else {
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...this.tributeVideo,
                    deadline: null,
                });
                await this.apiService.tributeVideo.updateDeadline(this.tributeVideo.id, { deadline: null });
            }
            this.showSnackbar({ message: 'Deadline updated.' });
        },
        handleClickNext() {
            if (this.state.isContributor || this.state.isFamily) {
                // If the fields aren't filled out then prevent going to next state
                if (isEmpty(this.contributor.name) || isEmpty(this.contributor.relationship)) {
                    this.showSnackbar({ messaage: 'Please fill out the fields.', color: 'error' });
                    return;
                }
                this.$emit('setAnonUserDetails', this.contributor);
            }
            this.$emit('next-step');
        },
        async createTributeVideo() {
            const slug = this.serviceSlug;
            if (!slug) {
                this.showSnackbar({ messaage: 'Invalid service slug', color: 'error' });
                return;
            }

            try {
                let tributeData = {
                    deadline: this.deadline.date,
                };

                this.loading = true;
                this.block(true);
                const tributeResp = await this.apiService.tributeVideo.createTributeVideoByService(slug, tributeData);

                if (!tributeResp.data.eventId) {
                    throw new Error('Error creating  tribute video');
                }

                const eventId = tributeResp.data.eventId;

                console.log(this.image, 'image');

                if (this.image.file) {
                    const photoResp = await this.uploadPhoto(eventId);
                    console.log(photoResp, 'initial photo resp');
                }

                this.$emit('tribute-video-created', tributeResp.data);
            } catch (error) {
                console.log(error, 'error creating tribute video');
            } finally {
                this.loading = false;
                this.block(false);
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        parseDeadline(deadline) {
            if (deadline) {
                const localDeadline = this.$moment.utc(deadline).local();
                this.deadline.date = localDeadline.toISOString();
            }
        }
    },
    async mounted() {
        if (this.state.token) {
            this.token = this.state.token;
        } else {
            await this.setAuthToken();
        }
        this.apiService = initApiServices(this.token);
        if (this.tributeVideo?.deadline) {
            this.parseDeadline();
        }
        if (this.state.isFamily || this.state.isContributor) {
            const tributeUserDetails = localStorage.getItem('tributeContributor');
            if (typeof tributeUserDetails === 'string') {
                this.contributor = JSON.parse(tributeUserDetails);
                this.contributorDetailsSet = true;
            }
        }
    },
    watch: {
        '$props.tributeVideo': {
            handler(newVal) {
                if (newVal.deadline) {
                    this.parseDeadline(newVal.deadline);
                }
            },
            deep: true,
            immediate: true,
        },
        state: {
            handler(newVal, oldVal) {
                if (newVal?.anonUserDetails) {
                    if (newVal.anonUserDetails?.name) {
                        this.contributor.name = newVal.anonUserDetails.name;
                        
                    }
                    if (newVal.anonUserDetails?.relationship) {
                        this.contributor.relationship = newVal.anonUserDetails.relationship;
                    }
                    this.contributorDetailsSet = true;
                }

            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.step-setup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.container {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-items: center;
}

.deadline-container,
.image-upload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

// h2 {
//     color: #6b7280;
//     // font-weight: 600;
//     // font-size: 12px;
//     // line-height: 16px;
//     text-transform: uppercase;
// }

// p {
//     color: #111827;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 20px;
// }

.select-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
}

.select-button {
    padding: 10px;
    width: 100%;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    border-radius: 6px;
}

.secondary-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;

    &:hover {
        background-color: $light-gray;
    }
}

.danger-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 4px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;

    &:hover {
        background: #ffd3d3;
    }
}

#image {
    display: none;
}

// .delete-image {
//     // position: absolute;
//     // bottom: 5px;
//     // right: 5px;
//     background: #fee2e2;
//     border: 1px solid #fca5a5;
//     border-radius: 4px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 10px;
//     // width: 24px;
//     // height: 24px;
//     cursor: pointer;
// }

.primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    background-color: #ea580c;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 6px;
    transition: all 0.3s;
}

.primary-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pending-slide {
    height: 100%;
    width: 100%;

    img {
        opacity: 0.4;
        height: 100%;
        width: 100%;
        max-width: 300px;
        object-fit: cover;
    }
    

    .progress-indicator {
        width: 93%;
        display: inline-block;
        height: 5px;
        bottom: 10px;
        position: absolute;
        left: 0;
        right: 0;
        background: #ffedd5;
        margin: auto;
        border-radius: 10rem;
    }

    .progress-slider {
        height: 100%;
        background: #f97316;
        border-radius: 10rem;
        transition: width 200ms ease-in-out;
    }
}

.profile-wrapper {
    aspect-ratio: 1;
    border-radius: 10rem;
    width: 100%;
    max-width: 300px;
    overflow: hidden;
    &.disabled {
        opacity: 1;
        cursor: auto;
    }
}

.profile-wrapper:not(.disabled):hover {
    opacity: 0.8;
}

.image-preview {
    // position: relative;
    display: inline-block;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    aspect-ratio: 1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.image-preview img {
    border-radius: 8px;
}

.profile-place-holder {
    background-color: #dbe2e8;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    h1 {
        // color: white;
        margin: 0;
        padding: 0;
    }
}
.field {
    width: 100%;
}
</style>
