import ApiService from './api.service';

const TributeTemplateService = token => {
    const api = ApiService.createApiInstance(token);

    return {
        getTemplates(params) {
            return api.get(`/TributeVideoTemplate`, { params: params });
        },
        getTemplate(id) {
            return api.get(`TributeVideoTemplate/get-template-with-fields/${id}`);
        },
        getTemplateSource(creatomateId) {
            return api.get(`/TributeVideoTemplate/template-source/${creatomateId}`);
        },
        getSelected(id) {
            return api.get(`TributeVideo/selected-templates/${id}`);
        },
        updateSelectedTemplates(id, data) {
            return api.post(`TributeVideo/selected-templates/${id}`, data);
        },
        selectTemplate(tributeId, templateId, mods = null) {
            return api.post(`TributeVideo/selected-templates/${tributeId}/${templateId}`, mods);
        },
        selectTemplateWithFont(tributeId, templateData) {
            return api.post(`TributeVideo/selected-templates/${tributeId}`, templateData);
        },
    };
};

export default TributeTemplateService;
