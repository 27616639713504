<template>
    <div>
        <RightPanelModal v-model="showPanel">
            <template #title>
                <div style="width: 100%" class="d-flex align-center justify-space-between">
                    <span class="pa-3 pb-0"> Changelog </span>
                    <v-btn @click="togglePanel" fab small depressed>
                        <font-awesome-icon icon="fa-regular fa-x"></font-awesome-icon>
                    </v-btn>
                </div>
            </template>

            <template #content>
                <v-list class="px-3">
                    <template v-for="(record, index) in changelogRecords">
                        <v-list-item three-line>
                            <v-list-item-icon>
                                <div
                                    class="icon-container"
                                    :style="{
                                        backgroundColor: rgbaColor(tributeChangelogActions[record.action].color, 0.1),
                                        borderColor: rgbaColor(tributeChangelogActions[record.action].color, 0.2),
                                    }"
                                >
                                    <font-awesome-icon
                                        :style="{ color: tributeChangelogActions[record.action].color }"
                                        :icon="tributeChangelogActions[record.action].icon"
                                    ></font-awesome-icon>
                                </div>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title class="mb-2">
                                    <span>
                                        {{ record.friendlyDetails }}
                                    </span>
                                </v-list-item-title>
                                <v-list-item-subtitle class="mb-3">
                                    Changed by {{ record.userEmail }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="text-caption">
                                    <font-awesome-icon icon="fa-regular fa-clock" class="mr-2"></font-awesome-icon>
                                    <span v-if="record.createDate">
                                        {{ $moment(record.createDate).format('l') }} |
                                        {{ $moment(record.createDate).format('LTS') }}
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-chip small v-if="changeWasRendered(record)">Rendered</v-chip>
                                <v-chip small v-else color="#FEF3C7" text-color="#92400E">Out Of Sync</v-chip>
                            </v-list-item-action>
                        </v-list-item>

                        <v-divider v-if="index != changelogRecords.length - 1" class="ma-0"></v-divider>
                    </template>

                    <div v-if="continuationToken" v-intersect="onIntersect">
                        <v-divider></v-divider>
                        <div class="d-flex justify-center pa-3">
                            <v-btn
                                :loading="loading"
                                :disabled="loading"
                                depressed
                                class
                                @click="fetchChangelogEventsNextPage(tributeVideoId)"
                                >Load More</v-btn
                            >
                        </div>
                    </div>
                </v-list>
            </template>

            <template #actions>
                <div class="p-3 pt-0 d-flex flex-wrap" style="width: 100%; gap: 12px">
                    <div class="flex-grow-1">
                        <v-btn
                            @click="undoAllChanges(tributeVideoId)"
                            :disabled="loading"
                            style="border-color: #d1d5db"
                            outlined
                            depressed
                            block
                        >
                            <font-awesome-icon icon="fa-regular fa-arrow-rotate-left" class="mr-2"></font-awesome-icon>
                            Undo Changes</v-btn
                        >
                    </div>
                    <div class="flex-grow-1">
                        <v-btn
                            @click="handleRerenderSubmit"
                            depressed
                            color="#ff530d"
                            block
                            :dark="!loading"
                            :disabled="loading"
                            :loading="loading"
                            >Rerender Tribute Video</v-btn
                        >
                    </div>
                </div>
            </template>
        </RightPanelModal>

        <slot name="activator" :on-click="togglePanel"> </slot>
    </div>
</template>
<script>
import RightPanelModal from '@/components/Tribute/Layout/RightPanelModal.vue';
import TributeVideoService from '@/services/tributeVideo.service';
import { tributeChangelogActions } from '@/constants';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            tributeChangelogActions,
            tributeApi: null,
            showPanel: false,
            pageSize: 24,
            continuationToken: null,
            lastRender: null,
            changelogRecords: [],
            loading: true,
        };
    },
    components: {
        RightPanelModal,
    },
    props: {
        tributeVideoId: {
            type: Number,
            required: true,
        },
    },
    watch: {
        async showPanel(newVal) {
            if (newVal) {
                await this.fetchChangelogEventsFirstPage(this.tributeVideoId);
            } else {
                this.changelogRecords = [];
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        handleRerenderSubmit() {
            this.$emit('init-rerender');
            this.showPanel = false;
        },
        async undoAllChanges(tributeVideoId) {
            try {
                this.loading = true;

                await this.tributeApi.undoAllChangesSinceRender(tributeVideoId);

                this.showPanel = false;
                this.showSnackbar({ message: 'Changes reverted' });
            } catch (error) {
                console.log(error, 'error undoing changes');
                this.showSnackbar({ message: 'Error undoing changes', color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        submitRerender() {
            console.log('TODO: submit rerender');
        },
        onIntersect(entries, observer) {
            if (!entries[0]?.isIntersecting) return;

            this.fetchChangelogEventsNextPage(this.tributeVideoId);
        },
        togglePanel() {
            this.showPanel = !this.showPanel;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async fetchChangelogEventsFirstPage(tributeId) {
            this.loading = true;

            try {
                const { data: res } = await this.tributeApi.getChangelogEvents(tributeId, this.pageSize);

                if (res.lastRender) {
                    this.lastRender = res.lastRender;
                }

                if (res?.records?.continuationToken) {
                    this.continuationToken = res.records.continuationToken;
                }

                if (res?.records?.results) {
                    this.changelogRecords = res.records.results;
                }
            } catch (error) {
                console.log(error, 'error fetching changelog events');
            } finally {
                this.loading = false;
            }
        },
        async fetchChangelogEventsNextPage(tributeId) {
            if (!this.continuationToken || this.loading) return;

            this.loading = true;

            try {
                const { data: res } = await this.tributeApi.getChangelogEvents(
                    tributeId,
                    this.pageSize,
                    this.continuationToken,
                );

                this.continuationToken = res?.records?.continuationToken;

                if (res?.records?.results) {
                    this.changelogRecords = [...this.changelogRecords, ...res.records.results];
                }
            } catch (error) {
                console.log(error, 'error fetching changelog events');
            } finally {
                this.loading = false;
            }
        },
        changeWasRendered(item) {
            if (this.lastRender == null) {
                return false;
            } else {
                return item.createDate < this.lastRender;
            }
        },
        rgbaColor(hex, opacity) {
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);
            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.tributeApi = TributeVideoService(this.token);

        this.fetchChangelogEventsFirstPage(this.tributeVideoId, this.pageSize);
    },
};
</script>
<style lang="scss" scoped>
.icon-container {
    height: 40px;
    width: 40px;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid;
}
</style>
