<template>
    <div style="position: relative">
        <v-select
            dense
            outlined
            background-color="white"
            readonly
            ref="textField"
            @click="menu = !menu"
            class="selected-font"
            item-text="family"
            item-value="family"
            :items="selectItems"
            v-model="activeFont"
            style="cursor: pointer"
        >
            <template v-slot:selection="{ item, index }">
                <span :style="computedFont ? { 'font-family': computedFont } : {}">{{ item.family }}</span>
            </template>
        </v-select>
        <div class="text-field-overlay" @click="menu = !menu"></div>
        <div id="menu-container">
            <v-menu v-model="menu" attach="#menu-container" :close-on-content-click="true" :nudge-width="600" offset-y>
                <v-card>
                    <v-virtual-scroll ref="scrollList" :items="fonts" height="300" item-height="64">
                        <template v-slot:default="{ item }">
                            <v-list-item
                                @click="selectFont(item)"
                                v-intersect="handleIntersect"
                                :key="item.family"
                                :id="item.family"
                            >
                                <v-list-item-title :style="{ fontFamily: item.family }">{{
                                    item.family
                                }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-menu>
        </div>
    </div>
</template>
<script>
export default {
    model: {
    prop: 'value',
    event: 'input',
  },
    data() {
        return {
            fonts: [],
            visibleItems: new Set(),
            menu: false,
            observer: null,
            windowHeight: 0,
            rowHeight: 50,
            visibleItemCount: 0,
            scrollPosition: 0,
            activeFont: '',
            selectItems: [],
        };
    },
    computed: {
        computedFont() {
            return this.activeFont.family;
        },
    },
    watch: {
        computedFont() {
            this.applyFontStyle();
            this.selectItems = [this.activeFont];
            this.$emit('font-changed', this.activeFont);
        },
    },
    methods: {
        handleIntersect(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.loadFont(entry.target.id);
                } else {
                    if (entry.target.id != this.activeFont.family) {
                        this.unloadFont(entry.target.id);
                    }
                }
            });
        },
        applyFontStyle() {
            this.$nextTick(() => {
                const input = this.$refs.textField.$el.querySelector('input');
                if (input) {
                    input.style.fontFamily = this.computedFont;
                }
            });
        },
        selectFont(font) {
            this.activeFont = font;
        },
        setFont(fontFamily) {
            console.log('googledropdown', fontFamily)
            var found = this.fonts.find(x => x.family == fontFamily);

            if (found) {
                this.activeFont = found;
                this.loadFont(this.activeFont.family);
            }
        },
        getFonts() {
            this.axios
                .get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.VUE_APP_GOOGLE_FONTS_KEY}`)
                .then(resp => {
                    this.fonts = resp.data.items;
                })
                .catch(err => {
                    console.log(err, 'google font error');
                });
        },
        loadFont(fontFamily) {
            const fontName = fontFamily.replace(/\s+/g, '+'); 
            const link = document.createElement('link');
            link.href = `https://fonts.googleapis.com/css?family=${fontName}&display=swap`;
            link.rel = 'stylesheet';
            document.head.appendChild(link);
        },
        unloadFont(fontFamily) {
            const fontName = fontFamily.replace(/\s+/g, '+'); 
            const links = document.querySelectorAll(`link[href*="fonts.googleapis.com/css?family=${fontName}"]`);

            links.forEach(link => {
                document.head.removeChild(link);
            });
        },
    },
    mounted() {
        this.getFonts();
    },
};
</script>
<style scoped>
.selected-font ::v-deep .v-text-field__slot input {
    font-family: v-bind(computedFont) !important;
}

.text-field-overlay {
    position: absolute;
    top: 0;
    height: 30px;
    width: 100%;
    cursor: pointer;
}
</style>
