<template>
  <div v-if="open" class="modal-overlay" @click="closeModal">
    <div class="modal-container" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <div class="icon-container">
            <svg xmlns="http://www.w3.org/2000/svg" class="warning-icon" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 3a1 1 0 01.894.553l6 12A1 1 0 0116 17H4a1 1 0 01-.894-1.447l6-12A1 1 0 0110 3zm0 2.618L5.618 15h8.764L10 5.618zM9 11a1 1 0 012 0v2a1 1 0 01-2 0v-2zm0 4a1 1 0 012 0 1 1 0 01-2 0z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="text-container">
            <h3 class="modal-title"><slot name="modal-title"></slot></h3>
            <p class="modal-text"><slot name="modal-content"></slot></p>
          </div>
        </div>

        <div class="modal-footer">
          <slot name="modal-footer">
            <button class="btn btn-cancel" @click="closeModal">Cancel</button>
            <button class="btn btn-confirm" @click="$emit('confirm')">Confirm</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped lang="scss">
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(107, 114, 128, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  transition: opacity 0.3s ease-in-out;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.modal-content {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 32rem;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.modal-header {
  display: flex;
  align-items: start;
  padding: 1.5rem;
}

.icon-container {
  background-color: #fee2e2;
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.warning-icon {
  width: 2rem;
  height: 2rem;
  color: #dc2626;
}

.text-container {
  margin-left: 1rem;
  text-align: left;
}

.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.5rem;
}

.modal-text {
  font-size: 0.875rem;
  color: #6b7280; 
}

.modal-footer {
  background: #f9fafb;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  border-top: 1px solid #e5e7eb;
}

.btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.btn-cancel {
  background: white;
  color: #374151; 
  border: 1px solid #d1d5db;
  
  &:hover {
    background: #f3f4f6;
  }
}

.btn-confirm {
  background: #dc2626;
  color: white;
  
  &:hover {
    background: #b91c1c;
    color: white;
  }
}

.btn-deactivate {
  background: #dc2626;
  color: white;
  
  &:hover {
    background: #b91c1c;
    color: white;

  }
}
</style>
