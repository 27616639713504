<template>
    <div class="tribute-view">
        <template v-if="!pinError">
            <TributeStepper
                :disabled="!tributeVideo.id || conditionallyDisableForContributors"
                :currentStep="currentStep"
                :stepData="steps"
                @change-step="val => handleChangeStep(val)"
                @init-rerender="initRerender"
            />

            <div class="step-content">
                <ChangelogPanel
                    v-if="tributeVideo.id && $auth.role.includes('SuperAdmin')"
                    :tributeVideoId="tributeVideo.id"
                    @init-rerender="initRerender"
                >
                    <template #activator="{ onClick }">
                        <v-fade-transition>
                            <div v-show="showOutOfSyncWarning">
                                <div
                                    @click="onClick"
                                    class="rounded-pill d-flex align-center out-of-sync-warning-outer"
                                >
                                    <div class="rounded-pill changes-detected-pill">
                                        <span>Changes Detected</span>
                                    </div>
                                    <span>{{ outOfSyncChanges.length }} changes since last render </span>
                                    <font-awesome-icon
                                        class="mr-2"
                                        style="font-size: 1rem; color: #f97316"
                                        icon="fa-regular fa-chevron-right"
                                    ></font-awesome-icon>
                                </div>
                            </div>
                        </v-fade-transition>
                    </template>
                </ChangelogPanel>

                <div :class="['middle-content', { expanded: tributeEditorExpanded }]" v-if="currentStep !== 'setup'">
                    <div class="action-column">
                        <v-btn class="text-normal" @click="handleChangeStep(prevStep)" :disabled="!prevStep">
                            <v-icon left> mdi-chevron-left </v-icon>
                            Prev
                        </v-btn>
                    </div>
                    <div class="preview-player">
                        <TributeRenderOverlay
                            v-if="currentStep === 'create' && showTributeOverlay"
                            @playPreview="playPreview"
                            @pausePreview="pausePreview"
                        ></TributeRenderOverlay>
                        <template v-if="standardRender && !tributeIsRendering">
                            <StyledVideojsPlayer :src="standardRender.url" />
                        </template>
                        <template v-else>
                            <CreatomatePreview
                                ref="creatomatePreview"
                                :source="previewSource"
                                :modifications="previewMods"
                            />
                        </template>
                    </div>
                    <v-btn
                        v-if="tributeEditorExpanded"
                        @click="togglePreview"
                        color="white"
                        class="text-normal"
                        style="max-width: 500px; width: 30%"
                    >
                        <font-awesome-icon icon="fa-regular fa-play-circle" class="fa-lg mr-2 play-icon" />
                        Preview Video
                    </v-btn>
                    <div class="action-column">
                        <v-btn
                            class="next-btn text-normal"
                            @click="handleChangeStep(nextStep)"
                            v-show="nextStep"
                            :disabled="currentStep === 'setup'"
                        >
                            Next
                            <v-icon right> mdi-chevron-right </v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
            <div class="workspace">
                <TributeSetup
                    v-show="currentStep === 'setup' && $route.params.slug"
                    :serviceSlug="$route.params.slug"
                    :tributeVideo="tributeVideo"
                    @tribute-video-created="data => handleTributeCreated(data)"
                    @next-step="handleChangeStep(nextStep.hash)"
                    @setAnonUserDetails="handleSetAnonUserDetails"
                />

                <v-tabs-items v-show="currentStep !== 'setup'" v-model="currentStep">
                    <v-tab-item transition="none" value="slides">
                        <ResizableWorkspace v-if="tributeVideo.eventId">
                            <ManagePhotos
                                :eventId="tributeVideo.eventId"
                                :tributeVideo="tributeVideo"
                                @pause-preview="pausePreview"
                                @refresh-preview="debouncedGeneratePreviewJson(tributeVideo.id)"
                            />
                        </ResizableWorkspace>
                    </v-tab-item>
                    <v-tab-item transition="none" :value="'theme'">
                        <ResizableWorkspace v-if="tributeVideo.eventId && tributeVideo.id">
                            <ManageThemes
                                :eventId="tributeVideo.eventId"
                                :tributeVideo="tributeVideo"
                                @pause-preview="pausePreview"
                                @refresh-preview="debouncedGeneratePreviewJson(tributeVideo.id)"
                            />
                        </ResizableWorkspace>
                    </v-tab-item>
                    <v-tab-item transition="none" :value="'music'">
                        <ResizableWorkspace v-if="tributeVideo.id">
                            <ManageMusic
                                :eventId="tributeVideo.eventId"
                                @refresh-preview="debouncedGeneratePreviewJson(tributeVideo.id)"
                                :tributeVideo="tributeVideo"
                            />
                        </ResizableWorkspace>
                    </v-tab-item>
                    <v-tab-item transition="none" :value="'create'">
                        <ResizableWorkspace v-if="tributeVideo.id">
                            <StepCreate
                                ref="createStep"
                                :tributeVideo="tributeVideo"
                                :slideChanges="slideChanges"
                                :themeChanges="themeChanges"
                                :musicChanges="musicChanges"
                                @render-started="onRenderStarted"
                            />
                        </ResizableWorkspace>
                    </v-tab-item>
                </v-tabs-items>
            </div>

            <MiniMusicPlayer :token="token" v-if="token" />
        </template>
        <template v-else>
            <div class="tribute-error-display">
                <img src="@/assets/tribute-logo.png" alt="Logo" />
                <div>{{ pinError ? 'Incorrect pin' : '????' }}</div>
            </div>
        </template>
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import TributeVideoService from '@/services/tributeVideo.service';
import { mapActions, mapGetters } from 'vuex';

import TributeStepper from '@/components/Tribute/TributeStepper.vue';
import TributeSetup from '@/components/Tribute/TributeSetup.vue';
import ManagePhotos from '@/components/Tribute/ManagePhotos.vue';
import ManageThemes from '@/components/Tribute/ManageThemes.vue';
import MiniMusicPlayer from '@/components/Tribute/Music/MiniMusicPlayer.vue';
import CreatomatePreview from '@/components/Tribute/Themes/CreatomatePreview.vue';
import ManageMusic from '@/components/Tribute/ManageMusic.vue';
import StepCreate from '@/components/Tribute/StepCreate.vue';
import ResizableWorkspace from '@/components/Tribute/Layout/ResizableWorkspace.vue';
import TributeRenderOverlay from '@/components/Tribute/RenderOverlay/RenderOverlay.vue';
import StyledVideojsPlayer from '../../components/videojs/StyledVideojsPlayer.vue';
import ChangelogPanel from '@/components/Tribute/Layout/ChangelogPanel.vue';

import { debounce, find, findIndex } from 'lodash';

const renderStatusDict = ['Planned', 'Rendering', 'Succeeded', 'Failed', 'Deleted'];

const stepData = [
    {
        name: "Person's Detail",
        hash: 'setup',
        default: true,
        family: true,
        contributor: true,
    },
    {
        name: 'Manage Slides',
        hash: 'slides',
        default: true,
        family: true,
        contributor: true,
    },
    {
        name: 'Set Theme',
        hash: 'theme',
        default: true,
        family: true,
        contributor: false,
    },
    {
        name: 'Add Music',
        hash: 'music',
        default: true,
        family: true,
        contributor: false,
    },
    {
        name: 'Create',
        hash: 'create',
        default: true,
        family: true,
        contributor: false,
    },
];

export default {
    name: 'TributeView',
    metaInfo: {
        title: 'Tribute Video',
    },
    components: {
        TributeStepper,
        TributeSetup,
        ManagePhotos,
        CreatomatePreview,
        ManageThemes,
        ManageMusic,
        MiniMusicPlayer,
        StepCreate,
        ResizableWorkspace,
        TributeRenderOverlay,
        StyledVideojsPlayer,
        ChangelogPanel,
    },
    provide() {
        const tributeRender = {};
        const state = {};

        Object.defineProperty(tributeRender, 'standardRender', {
            enumerable: true,
            get: () => this.standardRender,
        });
        Object.defineProperty(tributeRender, 'publicRender', {
            enumerable: true,
            get: () => this.publicRender,
        });

        Object.defineProperty(state, 'isFamily', {
            enumerable: true,
            get: () => this.isFamilyPage,
        });
        Object.defineProperty(state, 'isContributor', {
            enumerable: true,
            get: () => this.isContributorPage,
        });
        Object.defineProperty(state, 'token', {
            enumerable: true,
            get: () => this.token,
        });
        Object.defineProperty(state, 'anonUserDetails', {
            enumerable: true,
            get: () => this.anonUserDetails,
        });
        return {
            tributeRender,
            state,
        };
    },
    computed: {
        showOutOfSyncWarning() {
            return this.outOfSyncChanges.length > 0 && !this.tributeEditorExpanded;
        },
        prevStep() {
            const curStepIndex = findIndex(this.steps, { hash: this.currentStep });
            if (this.steps?.[curStepIndex - 1]) {
                return this.steps?.[curStepIndex - 1];
            }
            return null;
        },
        nextStep() {
            const curStepIndex = findIndex(this.steps, { hash: this.currentStep });
            if (this.steps[curStepIndex + 1]) {
                return this.steps[curStepIndex + 1];
            }
            return null;
        },
        lastStep() {
            return this.steps[this.steps?.length - 1];
        },
        currentStepItem() {
            const currentStep = find(this.steps, { hash: this.currentStep });
            return currentStep;
        },
        currentStepIndex() {
            return findIndex(this.steps, { hash: this.currentStep });
        },
        steps() {
            if (this.isFamilyPage) {
                return stepData.filter(step => step.family);
            }
            if (this.isContributorPage) {
                return stepData.filter(step => step.contributor);
            }
            return stepData;
        },
        isFamilyPage() {
            return this.$route.name === 'TributeVideoFamily';
        },
        isContributorPage() {
            return this.$route.name === 'TributeVideoContributor';
        },
        showTributeOverlay() {
            // Show overlay if the following is true
            // we're on step 5
            // we've started and/or completed a render
            //
            if (this.currentStep === 'create') {
                if (this.$store.state.tributeVideo.overlayDismissed) {
                    return false;
                }
                if (this.tributeIsRendering) {
                    return true;
                }
                if (this.$store.state.tributeVideo.renderStarted && this.$store.state.tributeVideo.renderCompleted) {
                    return true;
                }
            }
            return false;
        },
        tributeEditorExpanded() {
            return this.$store.state.tributeEditor.expanded;
        },
        tributeVideo: {
            get() {
                return this.$store.state.tributeVideo;
            },
            set(val) {
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...val,
                });
            },
        },
        ...mapGetters('tributeVideo', {
            minimumRequirementsMet: 'minimumCreateRequirementsMet',
            tributeIsRendering: 'tributeIsRendering',
        }),
        conditionallyDisableForContributors() {
            // return true to disable
            if (this.isContributorPage || this.isFamilyPage) {
                if (!this.anonUserDetails?.name?.length) {
                    return true;
                }
                if (!this.anonUserDetails?.relationship.length) {
                    return true;
                }
            }
            return false;
        },
    },
    data() {
        return {
            currentStep: 'setup',
            // tributeVideo: null,
            standardRender: null,
            publicRender: null,
            apiService: null,
            token: null,
            previewSource: null,
            previewMods: null,
            editMode: false,
            loading: true,
            outOfSyncChanges: [],
            slideChanges: [],
            themeChanges: [],
            musicChanges: [],
            anonUserDetails: null,
            pinError: false,
        };
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        initRerender() {
            this.handleChangeStep('create');

            setTimeout(() => {
                const ref = this.$refs.createStep;
                if (ref && typeof ref.confirmRenderIfNeeded === 'function') {
                    ref.confirmRenderIfNeeded();
                }
            }, 200);
        },
        handleChangeStep(val) {
            // if (val < 0) return;
            // if (val > 5) return;
            if (!this.tributeVideo.id) return;

            this.currentStep = val;
            // Pause preview player if currently rendering and going back into the 5th section
            if (this.currentStep === this.lastStep.hash) {
                if (this.tributeIsRendering) {
                    this.pausePreview();
                }
            }
        },
        handleSetAnonUserDetails(userDetails) {
            this.anonUserDetails = userDetails;
            localStorage.setItem('tributeContributor', JSON.stringify(userDetails));
        },
        togglePreview() {
            this.toggleExpandedState();
        },
        onRenderStarted() {
            // Do stuff when render starts
            this.pausePreview();
        },
        playPreview() {
            const previewInstance = this.$refs.creatomatePreview;
            if (previewInstance && previewInstance.playVideo) {
                previewInstance.playVideo();
            }
        },
        pausePreview() {
            const previewInstance = this.$refs.creatomatePreview;
            if (previewInstance && previewInstance.pauseVideo) {
                previewInstance.pauseVideo();
            }
        },
        createTributeVideo() {
            // If not on the last step take them there first
            // then after delay try and trigger the render
            if (this.currentStep !== this.lastStep.hash) {
                this.currentStep = this.lastStep.hash;
            }
            this.$nextTick(() => {
                this.$refs.createStep.submitTributeRender();
            });
        },
        handleTributeCreated(data) {
            this.tributeVideo = data;

            if (this.tributeVideo.eventId) {
                this.handleChangeStep(this.steps[this.currentStep]);
            }

            if (this.tributeVideo.id) {
                this.debouncedGeneratePreviewJson(this.tributeVideo.id);
            }

            this.tryFetchTributeVideo(this.$route.params.slug);
        },
        async setAuthToken() {
            if (this.isContributorPage || this.isFamilyPage) {
                const tmpApi = TributeVideoService(null);
                try {
                    const { data } = await tmpApi.getTributeToken(this.$route.params.slug, this.$route.params.pin);

                    if (data && data.token) {
                        switch (data.role) {
                            case 'Tribute':
                                if (!this.isContributorPage) {
                                    // Don't allow them to continue show error page
                                    this.pinError = true;
                                    return false;
                                }
                                break;
                            case 'TributeAdmin':
                                if (!this.isFamilyPage) {
                                    // Don't allow them to continue show error page
                                    this.pinError = true;
                                    return false;
                                }
                                break;
                        }
                        this.token = data.token;
                        this.role = data.role;
                    }
                } catch (error) {
                    console.error(error);
                    this.pinError = true;
                }
            } else {
                const response = await this.$auth.getIdTokenClaims();
                if (response && response.__raw) {
                    this.token = response.__raw;
                }
            }
        },
        async tryFetchTributeVideo(slug) {
            try {
                this.block(true);
                const resp = await this.apiService.tributeVideo.getTributeVideoByServiceSlug(slug);
                if (resp.data) {
                    this.tributeVideo = resp.data.tributeVideo;
                    this.standardRender = resp.data.standardRender;

                    this.publicRender = resp.data.copyrightSafeRender;

                    if (this.standardRender) {
                        // console.log('RENDER READY', this.standardRender);
                        if (this.$route.params.section) {
                            this.currentStep = this.$route.params.section;
                        } else if (this.currentStep !== this.lastStep.hash) {
                            this.currentStep = this.lastStep.hash;
                        }
                    } else {
                        this.currentStep = this.$route.params.section || 'slides';
                    }
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        ...this.tributeVideo,
                    });
                } else {
                    const serviceResp = await this.apiService.services.getBySlug(slug);
                    if (serviceResp.data) {
                        this.$store.dispatch('tributeVideo/updateTributeVideo', {
                            firstName: serviceResp.data.firstName,
                            lastName: serviceResp.data.lastName,
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching tribute video', error);
            } finally {
                this.block(false);
            }
        },
        async getSelectedPhotos() {
            let payload = {
                reversed: false,
                pageNumber: 0,
                pageSize: 25,
            };

            if (!this.tributeVideo?.eventId) {
                return;
            }
            const { data } = await this.apiService.tributePhoto.getPhotos(this.tributeVideo.eventId, payload);
            if (data?.total > 0) {
                this.$store.dispatch('tributeVideo/updateTributeVideoSelectedPhotos', data.photos);
            }
        },
        async getSelectedThemes() {
            if (!this.tributeVideo?.id) {
                return;
            }
            const { data } = await this.apiService.tributeTemplate.getSelected(this.tributeVideo.id);
            if (data.templates?.length) {
                this.$store.dispatch('tributeVideo/updateTributeVideoSelectedTemplates', data.templates);
            }
        },
        async getSelectedSongs() {
            if (!this.tributeVideo?.id) {
                return;
            }
            const { data: songs } = await this.apiService.tributeSong.getSelected(this.tributeVideo.id);
            if (songs?.length) {
                this.$store.dispatch('tributeVideo/updateTributeVideoSelectedSongs', songs);
            }
        },
        async checkOutOfSync(tributeRenderId) {
            const { data: res } = await this.apiService.tributeVideo.checkOutOfSync(tributeRenderId);

            if (res.outOfSyncChanges) {
                this.outOfSyncChanges = res.outOfSyncChanges;
            }

            this.slideChanges = this.outOfSyncChanges.filter(x => x.tableName === 'TributeVideoPhoto');
            this.themeChanges = this.outOfSyncChanges.filter(x => x.tableName === 'SelectedTemplates');
            this.musicChanges = this.outOfSyncChanges.filter(x => x.tableName === 'SelectedSongs');
        },
        async tryGeneratePreviewJson(tributeId) {
            try {
                if (this.tributeVideo.uploadingPhotos) return;

                console.log('generating preview');
                if (!tributeId) {
                    throw new Error('Invalid tribute video id');
                }

                var resp = await this.apiService.tributeVideo.generatePreviewJson(tributeId);
                // TODO: confirm this is the duration duration
                if (resp.data.duration) {
                    console.log('duration updated on tribute store');
                    // store the duration on the tribute video store
                    this.$store.dispatch('tributeVideo/updateTributeVideo', { totalDuration: resp.data.duration });
                }
                if (resp.data.creatomateJson) {
                    const creatomateJson = JSON.parse(resp.data.creatomateJson);

                    if (!creatomateJson.source) throw new Error('Error parsing preview source');

                    this.previewSource = creatomateJson.source;
                    console.log(this.previewSource, 'previewSource');

                    if (creatomateJson.modifications) {
                        this.previewMods = creatomateJson.modifications;
                    }
                }
            } catch (error) {
                console.log(error, 'error');
            }
        },
        handleChangelogNotification(data, eventType) {
            if (!this.standardRender.id || !this.tributeVideo.id || !this.tributeVideo.eventId) return;

            switch (eventType) {
                case 'tributeThemeUpdate':
                case 'tributeSongsUpdate':
                case 'tributeRenderSettings':
                    if (!data.tributeId || data.tributeId !== this.tributeVideo.id) return;
                    this.debouncedOutOfSyncRefresh(this.standardRender.id);
                    break;
                case 'mainPhotoReplace':
                    if (!data.id || data.id !== this.tributeVideo.id) return;
                    this.debouncedOutOfSyncRefresh(this.standardRender.id);
                    break;
                case 'notifyUpload':
                    if (!data.id || data.id !== this.tributeVideo.eventId) return;
                    this.debouncedOutOfSyncRefresh(this.standardRender.id);
                    break;
                case 'tributeRender':
                    if (!data.tributeVideoId || data.tributeVideoId !== this.tributeVideo.id);
                    this.debouncedOutOfSyncRefresh(this.standardRender.id);
                    break;
                default:
                    console.log(eventType, 'unhandled changlelog notification type');
                    break;
            }
        },
    },
    created() {
        this.debouncedGeneratePreviewJson = debounce(this.tryGeneratePreviewJson, 400);
        this.debouncedOutOfSyncRefresh = debounce(this.checkOutOfSync, 1000);
    },
    async mounted() {
        this.block(true);
        await this.setAuthToken();
        this.apiService = initApiServices(this.token);

        await this.tryFetchTributeVideo(this.$route.params.slug);
        if (this.isFamilyPage || this.isContributorPage) {
            // Check localstorage for stored user details
            const tributeUserDetails = localStorage.getItem('tributeContributor');
            if (typeof tributeUserDetails === 'string') {
                this.anonUserDetails = JSON.parse(tributeUserDetails);
            }
        }
        if (this.tributeVideo?.id) {
            this.tryGeneratePreviewJson(this.tributeVideo.id);
            // Fetch selected Photos, Music, & Themes
            // Photos
            this.getSelectedPhotos();
            // Themes
            this.getSelectedThemes();
            // Music
            this.getSelectedSongs();
        }

        if (this.standardRender) {
            this.checkOutOfSync(this.standardRender.id);
        }
        await this.$nextTick();
        this.loading = false;
        this.block(false);
    },
    sockets: {
        async NotifyTributeRender(data) {
            if (this.tributeVideo.id == data.tributeVideoId) {
                switch (renderStatusDict[data.status]) {
                    // TODO: See if Planned & Rendering should set the renderOverlay...
                    // Usecase: starting a render in a different tab or by another user?
                    case 'Planned':
                        break;
                    case 'Rendering':
                        break;
                    case 'Succeeded':
                        this.$nextTick(async () => {
                            // update the tributestore
                            await this.tryFetchTributeVideo(this.$route.params.slug);
                            // We've recieved the socket event, and we've fetched the latest json
                            // So if we have a standard render and url... can we safely assume that it has finished?
                            if (this.standardRender?.url) {
                                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                                    renderCompleted: new Date().toISOString(),
                                });
                            }
                        });
                        break;
                    case 'Failed':
                    case 'Delete':
                        // Render failed or was deleted, we should notify user and remove render overlay
                        this.showSnackbar({
                            message: 'Rendering the video has failed. Please wait and try again.',
                            color: 'error',
                        });
                        this.$store.dispatch('tributeVideo/updateTributeVideo', {
                            renderStarted: null,
                            renderCompleted: null,
                            overlayDismissed: true,
                        });

                        break;
                }
            }
            this.handleChangelogNotification(data, 'tributeRender');
        },
        async NotifyTributeJsonRefreshed(data) {
            //Hold preview refresh untill batched upload is finished
            if (this.tributeVideo.uploadingPhotos) return;

            if (this.tributeVideo?.id && data.id == this.tributeVideo.id) {
                this.debouncedGeneratePreviewJson(this.tributeVideo.id);
            }
        },
        NotifyUpload(data) {
            this.handleChangelogNotification(data, 'notifyUpload');
        },
        NotifyMainPhotoReplace(data) {
            this.handleChangelogNotification(data, 'mainPhotoReplace');
        },
        NotifyTributeThemeUpdate(data) {
            this.handleChangelogNotification(data, 'tributeThemeUpdate');
        },
        NotifyTributeSongsUpdate(data) {
            this.handleChangelogNotification(data, 'tributeSongsUpdate');
        },
        NotifyTributeRenderSettings(data) {
            this.handleChangelogNotification(data, 'tributeRenderSettings');
        },
    },
    watch: {
        // Update route with current step as it changes
        currentStep(newVal, oldVal) {
            if (newVal !== oldVal && this.$route.params.section !== newVal) {
                const currentParams = this.$route.params;
                currentParams.section = newVal;
                this.$router.push({ name: this.$route.name, params: currentParams });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tribute-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #f3f4f6;
    overflow-y: auto;
    overflow-x: hidden;
}

.step-content {
    width: 100%;
    display: flex;
    max-width: 800px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: auto;
    transition: height 0.8s ease;
    flex-grow: 1;
    gap: 4px;
    margin: 4px;
    // This overflow is causing the buttons to now be visible on bigger screens, revisit?
    // overflow: hidden;
    &:empty {
        max-height: 8vh;
    }
}

.preview-player {
    max-width: 800px;
    width: auto;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 8px;
    top: 0;
    position: relative;
    transition: top 400ms ease-in-out;
    flex: 1;
    aspect-ratio: 16 / 9;
    &.expanded {
        top: -50vh;
    }
}
.middle-content {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100vh - 34vh - 140px);
    .preview-player {
        align-self: center;
        .preview-component-container {
            margin: 0 auto;
            max-height: min(49vh, 430px);
        }
    }
    .action-column {
        min-width: 120px;
        text-align: center;
        align-content: center;
    }
    &.expanded {
        height: fit-content;
        margin-top: -12px;

        .preview-player {
            height: 0;
            overflow: hidden;
            display: none;
        }
    }
    @include mobile() {
        &:not(.expanded) {
            flex-direction: row;
            flex-wrap: wrap;

            .preview-player {
                order: 0;
                flex: 1 1 100%;
            }
            .action-column {
                order: 1;
                flex: 1 0 50%;
            }
        }
    }
}
.play-icon {
    color: $primary-grey;
}
.next-btn {
    background-color: $primary-orange !important;
    color: white !important;
}

.changelog-panel-activator {
    position: fixed;
    bottom: 12px;
    right: 12px;
}

.workspace {
    position: sticky;
    bottom: 0;
    width: 100%;
}

.out-of-sync-warning-outer {
    border: 1px solid #fcd34d;
    background-color: #fffbeb;
    color: #92400e;
    gap: 8px;
    padding: 4px;
    cursor: pointer;
    font-size: 12px;

    .changes-detected-pill {
        background-color: #fef3c7;
        padding: 2px 10px;
    }
}
.loading-overlay {
    display: flex;
    height: 100vh;
    align-items: center;
}
::v-deep {
    .styled-videojs {
        .vjs-error-display,
        .vjs-modal-dialog-content {
            z-index: 0;
        }
    }
}
.tribute-error-display {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    inset: 0;
    align-items: center;
    justify-content: center;
}
</style>
