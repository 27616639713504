<template>
    <div>
        <slot name="activator" :open="handleOpen">
            <v-btn v-if="!hideButton" dark depressed small color="#ff530d" @click="handleOpen">Upload +</v-btn>
        </slot>

        <dashboard-modal v-if="uppy" :uppy="uppy" :props="dashboardProps" :open="isOpen" />

        <branded-modal @close="warningModal = false" max-width="500px" v-model="warningModal">
            <template v-slot:title>
                <h2>Upload Music</h2>
            </template>

            <template v-slot:body>
                <v-card-text>
                    <p style="font-size: 0.9rem">
                        By selecting "Acknowledge and Confirm," you affirm that all music uploaded to MemoryShare is
                        properly licensed. You agree to take responsibility for defending and protecting MemoryShare,
                        Inc., along with its owners, employees, agents, and successors, against any third-party claims
                        arising from a failure to meet this requirement.
                    </p>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn depressed color="#ff530d" dark @click="confirmWarning">Acknowledge and Confirm </v-btn>
                </v-card-actions>
            </template>
        </branded-modal>

        <branded-modal @close="userNotFoundModal = false" max-width="400px" v-model="userNotFoundModal">
            <template v-slot:title>
                <h2>Warning!</h2>
            </template>

            <template v-slot:body>
                <v-card-text>Unauthorized Access: UserId not found</v-card-text>
                <v-card-actions>
                    <div style="width: 100%" class="text-center">
                        <v-btn depressed color="error" @click="userNotFoundModal = false">Ok</v-btn>
                    </div>
                </v-card-actions>
            </template>
        </branded-modal>

        <input type="file" id="uppy-file-input" style="display: none" />
    </div>
</template>
<script>
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import FileInput from '@uppy/file-input';

import UppyAddUploaderPlugin from '@/utilities/uppy-add-uploader-plugin.js';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { log } from '@/utilities/debug-helpers';
import { mapActions } from 'vuex';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import TributeSongService from '@/services/tributeSong.service';
import { MEDIA_UPLOADER_TYPES, getUploaderEndpoints } from '@/constants';
const { TRIBUTE_VIDEO_SONG_ARTWORK } = MEDIA_UPLOADER_TYPES;
const endpoints = getUploaderEndpoints(TRIBUTE_VIDEO_SONG_ARTWORK);
const jsmediatags = require('jsmediatags/dist/jsmediatags.min');

export default {
    data() {
        return {
            uppy: null,
            isOpen: false,
            warningModal: false,
            tempFiles: [],
            userNotFoundModal: false,
            dashboardProps: {
                theme: 'light',
                onRequestCloseModal: this.handleClose,
                hideUploadButton: false,
                height: 450,
                showProgressDetails: true,
                browserBackButtonClose: true,
                closeModalOnClickOutside: true,
                proudlyDisplayPoweredByUppy: false,
                // note: '1MB file size limit',
                note: 'Tribute Music Uploader',
            },
            songService: null,
        };
    },
    computed: {
        isAdmin() {
            return this.$auth.impersonating || this.$auth.role.includes('SuperAdmin');
        },
    },
    components: {
        DashboardModal,
        BrandedModal,
    },
    props: {
        hideButton: {
            type: Boolean,
            defalult: false,
        },
        token: {
            type: String,
            required: true,
        },
        funeralHomeId: {
            type: Number,
            required: false,
        },
        openToFileExplorer: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.songService = TributeSongService(this.token);
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        handleOpen() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.openUppyUI();
            } else {
                this.warningModal = true;
            }
        },
        openUppyUI() {
            if (this.openToFileExplorer) {
                this.openFileSelection();
            } else {
                this.isOpen = true;
            }
        },
        confirmWarning() {
            this.warningModal = false;
            this.openUppyUI();
        },
        handleClose() {
            this.isOpen = false;
        },
        async getArtworkUploadUrl(id, fileName) {
            return this.axiosInstance.get(`${endpoints.sas}/${id}/${fileName}`);
        },
        async getUploadUrl(id, file) {
            return this.axiosInstance.get(`TributeVideoSong/song/sas/${id}/${file.name}`);
        },
        async uploadToEndpoint(file, endpoint, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(file.data);
                reader.addEventListener('error', error => {});
                reader.addEventListener('load', async () => {
                    const config = {
                        onUploadProgress: onProgressUpdate,
                    };
                    try {
                        await this.axios
                            .create({
                                headers: {
                                    'Content-Type': file.type,
                                    'x-ms-blob-type': 'BlockBlob',
                                },
                            })
                            .put(endpoint, reader.result, config)
                            .then(res => {});
                        resolve('done');
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        async createSong(data) {
            return this.axiosInstance.post('/TributeVideoSong', data);
        },
        updatePlayableLink(id, fileName) {
            return this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + `/TributeVideoSong/song/${id}`, JSON.stringify(fileName))
                .then(res => {
                    return res.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        cleanFileName(file) {
            const lastDot = file.name.lastIndexOf('.');
            let cleanedName = file.name.substring(0, lastDot);
            cleanedName = cleanedName.replaceAll('_', ' ');
            return cleanedName;
        },
        getMusicFileDuration(file) {
            return new Promise((resolve, reject) => {
                const src = URL.createObjectURL(file.data);

                const audioElement = new Audio(src);
                audioElement.addEventListener('loadeddata', () => {
                    let duration = Math.floor(audioElement.duration);
                    return resolve(duration);
                    // The duration variable now holds the duration (in seconds) of the audio clip
                });
                audioElement.addEventListener('error', () => {
                    return reject(error);
                });
            });
        },

        uploadSuccessful() {
            this.tempFiles = [];
            this.uppy.cancelAll();
        },
        async uploadFiles() {
            return new Promise(async (resolve, reject) => {
                // Tell dashboard the the upload has started
                this.uppy.emit('upload-start', this.tempFiles);

                for (let i = 0; i < this.tempFiles.length; i++) {
                    let SELECTED_FILE = this.tempFiles[i];
                    const uFile = this.uppy.getFile(SELECTED_FILE.id);
                    // console.log({ SELECTED_FILE, uFile })
                    try {
                        const cleaned = this.cleanFileName(SELECTED_FILE);

                        const duration = await this.getMusicFileDuration(SELECTED_FILE);
                        // // Create DB instance
                        let songDto = {
                            title: uFile?.meta?.title || cleaned,
                            album: uFile?.meta?.album || '',
                            artist: uFile?.meta?.artist || '',
                            duration: duration ? duration : 0,
                        };
                        if (this.funeralHomeId) {
                            songDto.funeralHomeId = this.funeralHomeId;
                        }

                        let { data: created } = await this.createSong(songDto);
                        // Do we have an artwork that needs uploading?
                        if (uFile.preview && uFile.meta.artworkFile && this.isAdmin) {
                            // we have a artwork to upload
                            // Get Arwork Sas info
                            let {
                                data: { sas, fileName },
                            } = await this.getArtworkUploadUrl(created.id, uFile.meta.artworkFile.name);
                            // TODO: Is this the correct way to get that final upload url?
                            // console.log('attempt to upload the artwork file');
                            // trigger the artwork upload
                            await this.uploadToEndpoint(uFile.meta.artworkFile, sas, ev => {
                                console.log('artwork upload', ev);
                            });
                            // TODO: this isn't working... how to set the final artwork on the song?
                            // call the update song artwork endpoint
                            const updatedSong = await this.songService.updateSongArtwork(created.id, fileName);
                            console.log('updatedSong after uploading artwork', updatedSong);
                        }
                        // // Get Sas info
                        let {
                            data: { sas },
                            data: { fileName },
                        } = await this.getUploadUrl(created.id, SELECTED_FILE);

                        // // Upload to azure storage
                        await this.uploadToEndpoint(SELECTED_FILE, sas, ev => {
                            // Emit progress update so Uppy Dashboard shows progress bar
                            this.uppy.emit('upload-progress', SELECTED_FILE, {
                                uploader: this,
                                bytesUploaded: ev.loaded,
                                bytesTotal: ev.total,
                            });
                        });

                        // // Save playable link to Db instance
                        const updated = await this.updatePlayableLink(created.id, fileName);

                        const uploadResp = {
                            status: 200,
                        };

                        this.uppy.emit('upload-success', SELECTED_FILE, uploadResp);
                        this.$emit('upload-success', updated);
                    } catch (error) {
                        //Error thrown if userId not found on backend
                        if (error?.response?.data.Message == 'Unauthorized access: UserId not found') {
                            console.log('specific error');
                            this.userNotFoundModal = true;
                            this.isOpen = false;
                        }

                        var status = -1;

                        if (error.response) {
                            status = error.response.status;
                        }

                        const response = {
                            status: status,
                        };

                        this.uppy.emit('upload-error', SELECTED_FILE, error, response);
                        console.log(error, 'upload error');
                        reject(error);
                    }
                }

                resolve('done');
            });
        },
        openFileSelection() {
            const input = this.uppy.getPlugin('FileInput');
            if (input) {
                input.handleClick();
            }
        },
        initUppy() {
            const controller = this;
            this.uppy = new Uppy({
                id: 'tribute-music-uploader',
                debug: true,
                autoProceed: false,
                // logger: Uppy.debugLogger,
                allowMultipleUploadBatches: false,
                restrictions: {
                    // maxFileSize: MAX_FILE_SIZE,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 10,
                    allowedFileTypes: ['.mp3'],
                },
            })
                .use(FileInput, {
                    target: '#uppy-file-input',
                    pretty: false,
                })
                .use(UppyAddUploaderPlugin, {
                    uploader: fileIds => {
                        this.uploadFiles()
                            .then(res => {
                                this.handleClose();
                                this.$emit('refresh');
                                this.showSnackbar({ message: 'Upload Complete' });

                                this.uploadSuccessful();
                            })
                            .catch(error => {
                                this.showSnackbar({ color: 'error', message: 'An error occurred during the upload' });
                            });
                    },
                })
                .on('file-added', file => {
                    if (!this.isOpen) {
                        this.isOpen = true;
                    }

                    jsmediatags.read(file.data, {
                        onSuccess: function (tag) {
                            const { title, artist, album, picture } = tag.tags;
                            if (title) {
                                const newName = title;
                                controller.uppy.setFileState(file.id, { name: newName });
                                controller.uppy.setFileMeta(file.id, { name: newName, artist, title });
                            }
                            if (album) {
                                controller.uppy.setFileMeta(file.id, { album });
                            }
                            if (picture && controller.isAdmin) {
                                const { data, format } = picture;
                                console.log('Mp3 has artwork:', format);
                                let base64String = '';
                                for (let i = 0; i < data.length; i++) {
                                    base64String += String.fromCharCode(data[i]);
                                }
                                const imageToExtensionMap = {
                                    'image/jpeg': '.jpg',
                                    jpeg: '.jpg',
                                    jpg: '.jpg',
                                    'image/png': '.png',
                                    png: '.png',
                                };
                                controller.uppy.setFileState(file.id, {
                                    preview: `data:${format};base64,${window.btoa(base64String)}`,
                                });
                                controller.uppy.setFileMeta(file.id, {
                                    artworkFile: {
                                        data: new Blob([new Uint8Array(data)], { type: format }),
                                        name: `${file.name.replace(
                                            '.mp3',
                                            imageToExtensionMap?.[format.toLowerCase()] || format.toLowerCase(),
                                        )}`,
                                        format,
                                    },
                                });
                            }
                            controller.tempFiles = [...controller.tempFiles, file];
                        },
                        onError: function (error) {
                            // error reading tags, so proceed as normal
                            controller.tempFiles = [...controller.tempFiles, file];
                        },
                    });
                })
                .on('file-removed', file => {
                    this.tempFiles = this.uppy.getFiles();
                });
        },
    },

    async created() {
        // await this.setAuthToken();
        this.createAxiosInstance();
        this.initUppy();
    },
    beforeDestroy() {
        this.uppy.close();
    },
};
</script>
<style lang=""></style>
