var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"action-bar mb-2"},[_c('div',{staticClass:"left-buttons"}),_c('div',{staticClass:"right-buttons"},[(_vm.tributeRender.standardRender)?_c('transition',{attrs:{"name":"slide-in"}},[_c('v-btn',{staticClass:"white-action-btn text-normal",attrs:{"loading":_vm.downloading,"elevation":0,"depressed":"","outlined":""},on:{"click":_vm.downloadTributeVideo}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-download"}}),_vm._v(" Download ")],1)],1):_vm._e(),(_vm.tributeRender.standardRender)?_c('transition',{attrs:{"name":"slide-in"}},[_c('button',{key:"embed-btn",staticClass:"white-action-btn",attrs:{"disabled":!_vm.tributeEmbedCode},on:{"click":function($event){_vm.showEmbedModal = true}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-code-simple"}}),_vm._v(" Embed Code ")],1)]):_vm._e(),_c('button',{staticClass:"orange-action-btn",attrs:{"disabled":!_vm.minimumCreateRequirementsMet || _vm.tributeIsRendering},on:{"click":_vm.confirmRenderIfNeeded}},[_vm._v(" Create Video ")])],1)]),_c('div',{staticClass:"slides-container"},[_c('div',{class:[
                'flex-grow-1',
                'slide photos',
                {
                    'out-of-sync': _vm.slideChanges.length > 0,
                    incomplete: _vm.selectedPhotoCount === 0 && _vm.slideChanges.length === 0,
                } ]},[_c('div',{staticClass:"slide-top"},[(_vm.selectedPhotoCount === 0)?_c('div',{staticClass:"slide-icon"},[_c('img',{attrs:{"src":_vm.photoIcon}})]):_c('div',{staticClass:"thumb-stack"},[_vm._l((_vm.topThreeSelectedPhotos),function(photo){return [_c('ImageThumbnail',{key:photo.url,staticClass:"stack-image",attrs:{"src":photo.url,"w":null,"h":60,"type":photo.mediaType}})]})],2)]),_c('div',{staticClass:"slide-bottom"},[_c('div',{staticClass:"left"},[(_vm.slideChanges.length > 0)?_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-clock"}}):(_vm.selectedPhotoCount > 0)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-check"}}):_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-info-circle"}})],1),_c('div',{staticClass:"right"},[_c('strong',[_vm._v("Slides")]),(_vm.slideChanges.length)?_c('p',{staticClass:"p-0"},[_vm._v(_vm._s(_vm.slideChanges.length)+" slide changes found")]):_c('p',{staticClass:"p-0"},[_vm._v("You've uploaded "+_vm._s(_vm.selectedPhotoCount)+" photos.")])])])]),_c('div',{class:[
                'flex-grow-1',
                'slide theme',
                {
                    'out-of-sync': _vm.themeChanges.length > 0,
                    incomplete: _vm.selectedThemeCount === 0 && _vm.themeChanges.length === 0,
                } ]},[_c('div',{staticClass:"slide-top"},[(_vm.selectedThemeCount === 0 || !_vm.selectedTheme)?_c('div',{staticClass:"slide-icon"},[_c('img',{attrs:{"src":_vm.exploreIcon,"alt":""}})]):_c('div',{staticClass:"thumb-stack singular"},[_c('ImageThumbnail',{staticClass:"stack-image",attrs:{"src":_vm.selectedTheme.url,"w":60,"h":60,"type":1}})],1)]),_c('div',{staticClass:"slide-bottom"},[_c('div',{staticClass:"left"},[(_vm.themeChanges.length > 0)?_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-clock"}}):(_vm.selectedThemeCount > 0)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-check"}}):_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-info-circle"}})],1),_c('div',{staticClass:"right"},[_c('strong',[_vm._v("Set Theme")]),(_vm.themeChanges.length)?_c('p',{staticClass:"p-0"},[_vm._v(_vm._s(_vm.themeChanges.length)+" theme changes found")]):_c('p',{staticClass:"p-0"},[_vm._v(_vm._s(_vm.selectedThemeCount > 0 ? 'Theme is set.' : 'Theme is not set.'))])])])]),_c('div',{class:[
                'flex-grow-1',
                'slide songs',
                {
                    'out-of-sync': _vm.musicChanges.length > 0,
                    incomplete: _vm.selectedSongCount === 0 && _vm.musicChanges.length === 0,
                } ]},[_c('div',{staticClass:"slide-top"},[(_vm.selectedSongCount === 0)?_c('div',{staticClass:"slide-icon"},[_c('img',{attrs:{"src":_vm.musicIcon,"alt":""}})]):_c('div',{class:['thumb-stack', _vm.topThreeSelectedSongs.length === 1 ? 'singular' : '']},[_vm._l((_vm.topThreeSelectedSongs),function(song){return [_c('ImageThumbnail',{key:song.id,staticClass:"stack-image",attrs:{"src":song.artwork || _vm.fallbackArtwork,"w":null,"h":60}})]})],2)]),_c('div',{staticClass:"slide-bottom"},[_c('div',{staticClass:"left"},[(_vm.musicChanges.length > 0)?_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-clock"}}):(_vm.selectedSongCount > 0)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-check"}}):_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-info-circle"}})],1),_c('div',{staticClass:"right"},[_c('strong',[_vm._v("Add Music")]),(_vm.musicChanges.length)?_c('p',{staticClass:"p-0"},[_vm._v(_vm._s(_vm.musicChanges.length)+" music changes found")]):_c('p',{staticClass:"p-0"},[_vm._v("You've added "+_vm._s(_vm.selectedSongCount)+" tracks.")])])])])]),_c('v-dialog',{attrs:{"overlay-opacity":0.85,"max-width":"500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var isActive = ref.isActive;
return [_c('v-card',[_c('v-card-text',{staticClass:"p-4"},[_c('h4',[_vm._v("Regenerate Tribute Video")]),_c('p',[_vm._v(" You've made changes to your tribute video. To reflect these changes, the video needs to be regenerated. A small fee will be charged. This will take a few minutes to complete. ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"cancel"},on:{"click":function () { return (_vm.showConfirmRenderModal = false); }}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () {
                                _vm.submitTributeRender();
                                _vm.showConfirmRenderModal = false;
                            }}},[_vm._v("Proceed to Regenerate")])],1)],1)]}}]),model:{value:(_vm.showConfirmRenderModal),callback:function ($$v) {_vm.showConfirmRenderModal=$$v},expression:"showConfirmRenderModal"}}),_c('v-dialog',{attrs:{"overlay-opacity":0.85,"max-width":"500"},model:{value:(_vm.showEmbedModal),callback:function ($$v) {_vm.showEmbedModal=$$v},expression:"showEmbedModal"}},[_c('v-card',[_c('v-card-text',{staticClass:"p-4"},[_c('h4',[_vm._v("Embed Code")]),_c('code',{staticClass:"embed-code"},[_vm._v(" "+_vm._s(_vm.tributeEmbedCode)+" ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"cancel"},on:{"click":function () { return (_vm.showEmbedModal = false); }}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.copyToClipboard(_vm.tributeEmbedCode)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-regular fa-clone"}}),_vm._v(" Copy")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }