<template>
    <div>
        <v-dialog max-width="1000px" v-model="display">
            <v-card :loading="loading">
                <v-card-title class="d-flex justify-content-center">
                    <h1>Out Of Sync Changes</h1>
                </v-card-title>
                <!-- <v-card-title v-if="lastRenderDate">Last Render: {{ $moment.utc(lastRenderDate).local().format('lll')}}</v-card-title> -->

                <div style="max-height: 500px; overflow: auto">
                    <v-card-text>
                        <v-row class="p-4" v-if="results">
                            <v-col class="mb-3" cols="12" v-for="(result, i) in results">
                                <v-row
                                    style="border-top: 2px solid black; border-bottom: 2px solid black"
                                    class="mb-3"
                                    v-if="i == lastRenderedChangeIndex"
                                >
                                    <v-col class="text-center" cols="12">
                                        <h4>Rendered Changes</h4>
                                    </v-col>
                                </v-row>
                                <v-row style="background-color: #f6f6f6; border-radius: 5px">
                                    <v-col cols="12" md="7">
                                        <div
                                            class="fill-height pa-2 d-flex flex-column justify-center"
                                            style="background-color: #fbfbfb; border-radius: 5px; gap: 5px"
                                        >
                                            <div>
                                                <v-chip
                                                    v-if="result.action >= 0"
                                                    dark
                                                    small
                                                    label
                                                    :color="tributeChangelogActions[result.action].color"
                                                >
                                                    {{ tributeChangelogActions[result.action].label }}
                                                </v-chip>
                                            </div>

                                            <div @click="logResult(result)">
                                                <div class="text-caption">
                                                    <span v-if="result.tributeRenderType == 1">Public Video</span>
                                                    <span v-else>Original Video</span>
                                                </div>
                                                <div v-if="result.friendlyDetails" class="d-flex flex-column">
                                                    <span v-for="detail in result.friendlyDetails.split(',')">{{
                                                        detail
                                                    }}</span>
                                                </div>
                                                <span v-else>Details not available</span>
                                            </div>

                                            <!-- <v-btn @click="deleteRecord1(result)">del</v-btn> -->
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="5">
                                        <div class="fill-height d-flex flex-wrap">
                                            <div class="fill-height d-flex flex-column justify-center flex-grow-1">
                                                <strong>{{
                                                    $moment(result.createDate).format('MM/DD/YY - h:mm:ss A')
                                                }}</strong>
                                                <div>
                                                    {{ result.userEmail }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    $auth.role.includes('SuperAdmin') &&
                                                    i == 0 &&
                                                    !changeWasRendered(result)
                                                "
                                                class="fill-height d-flex flex-column justify-center"
                                            >
                                                <custom-tooltip :tooltipProps="{ top: true }">
                                                    <template v-slot:activator>
                                                        <v-btn
                                                            :disabled="loading"
                                                            fab
                                                            text
                                                            small
                                                            @click.stop="undoChange(result)"
                                                        >
                                                            <font-awesome-icon
                                                                style="font-size: 1rem"
                                                                icon="fa-regular fa-rotate-left"
                                                            ></font-awesome-icon>
                                                        </v-btn>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span>Undo</span>
                                                    </template>
                                                </custom-tooltip>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions v-if="continuationToken && !loading">
                        <div class="d-flex flex-grow-1 justify-content-center">
                            <v-btn
                                v-intersect="onIntersect"
                                :disabled="loading"
                                :loading="loading"
                                depressed
                                @click="getChangelogResults(false)"
                                >More Results</v-btn
                            >
                        </div>
                    </v-card-actions>
                </div>

                <v-card-actions class="d-flex justify-center">
                    <v-btn depressed @click="display = false">Cancel</v-btn>
                    <v-btn depressed color="orange" :dark="!loading" :disabled="loading" @click="undoAllChanges"
                        >Undo All</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-if="recordToDelete" max-width="400px" v-model="deleteConfirmModal">
            <v-card>
                <v-card-title>Delete Record?</v-card-title>
                <v-card-text
                    >Id: <strong>{{ recordToDelete.id }}</strong></v-card-text
                >
                <v-card-text>
                    Are you sure you want to delete this tribute changelog record?
                    <strong>This cannot be undone.</strong>
                </v-card-text>
                <v-card-actions>
                    <div class="d-flex flex-grow-1 justify-space-between">
                        <v-btn depressed @click="cancelRecordDelete">Cancel</v-btn>
                        <v-btn
                            color="error"
                            :disabled="loading || changeWasRendered(recordToDelete)"
                            depressed
                            @click="submitDeleteRecord(recordToDelete.id)"
                            >Confirm</v-btn
                        >
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { tributeChangelogActions } from '@/constants';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            token: '',
            display: false,
            continuationToken: null,
            pageSize: 25,
            results: [],
            selectedRecord: null,
            deleteConfirmModal: false,
            recordToDelete: null,
            showRaw: false,
            tributeChangelogActions,
            loading: true,
            lastRenderDate: null,
            lastRenderedChangeIndex: null,
            unsupportedUndoActions: [14, 15, 16],
        };
    },
    components: {
        CustomTooltip,
    },
    watch: {
        display() {
            if (!this.display) {
                this.selectedRecord = null;
                this.recordToDelete = null;
                this.continuationToken = null;
                this.showRaw = false;
                this.results = [];
            } else {
                this.getChangelogResults(false);
            }
        },
        selectedRecord() {
            this.showRaw = false;
        },
    },
    props: {
        tributeId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        mainTributePhotoId: {
            get() {
                return this.$store.state.tributeVideo.mainPhotoId;
            },
        },
    },

    methods: {
        ...mapActions(['showSnackbar']),
        logResult(result) {
            console.log(result);
        },
        onIntersect(entries, observer) {
            if (entries[0].isIntersecting) {
                this.getChangelogResults(false);
            }
        },
        changeWasRendered(item) {
            if (this.lastRenderDate == null) {
                return false;
            } else {
                return item.createDate < this.lastRenderDate;
            }
        },
        undoChange(item) {
            if (this.mainTributePhotoId > 0) {
                if (
                    item.action == 'Add' &&
                    item.tableName == 'TributeVideoPhoto' &&
                    item.itemId == this.mainTributePhotoId
                ) {
                    this.showSnackbar({ message: 'Cannot remove main photo', color: 'error' });
                    this.selectedRecord = null;
                    this.recordToDelete = null;
                    this.continuationToken = null;
                    this.showRaw = false;
                    this.results = [];
                    return;
                }
            }

            if (this.unsupportedUndoActions.includes(item.action)) {
                this.showSnackbar({ message: 'Can not undo this action', color: 'error' });
                return;
            }

            const found = this.results.find(x => x.id == item.id);

            this.loading = true;
            this.axiosInstance
                .post(`/TributeVideo/changelog/undo/${item.tributeId}/${item.id}`)
                .then(resp => {
                    if (found) {
                        const index = this.results.indexOf(found);
                        this.results.splice(index, 1);
                    }
                    this.showSnackbar({ message: 'Undo successful' });
                    this.$emit('refresh-summary');
                    this.$emit('refresh-sync');
                })
                .catch(error => {
                    console.log(error, 'error @ undo');
                    this.showSnackbar({ message: 'Error occurred during undo', color: 'error' });
                })
                .finally(() => {
                    this.selectedRecord = null;
                    this.showRaw = false;
                    this.loading = false;
                });
        },
        addSpaceToCapitals(str) {
            if (str && typeof str == 'string') {
                return str.replace(/([a-z])([A-Z])/g, '$1 $2');
            }
        },
        getChangelogResults(replaceResults = false) {
            let params = {
                continuationToken: this.continuationToken,
                pageSize: this.pageSize,
            };
            this.loading = true;

            this.axiosInstance
                .get(`/TributeVideo/changelog/${this.tributeId}`, { params })
                .then(resp => {
                    const newResults = [...resp.data.records.results];
                    var results = [];
                    if (replaceResults) {
                        results = newResults;
                    } else {
                        results = [...this.results, ...newResults];
                    }

                    this.results = this.filterDuplicatesById(results);

                    this.lastRenderDate = resp.data.lastRender;
                    this.continuationToken = resp.data.records.continuationToken;

                    const lastRenderedChange = this.results.find(x => this.changeWasRendered(x));
                    if (lastRenderedChange) {
                        this.lastRenderedChangeIndex = this.results.indexOf(lastRenderedChange);
                    } else {
                        this.lastRenderedChangeIndex = null;
                    }
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error loading changelog results', color: 'error' });
                    console.log(error, 'Error loading changelog results');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleRoleColor(role) {
            if (role && typeof role == 'string') {
                switch (role.toLowerCase()) {
                    case 'superadmin':
                        return 'black';
                        break;
                    case 'funeralhome':
                        return '#651FFF';
                        break;
                    case 'owner':
                        return 'teal';
                        break;
                    case 'regionalmanager':
                        return '#00C853';
                        break;
                    case 'tribute':
                        return '#0f578b';
                        break;
                    case 'tributeadmin':
                        return '#ab0658';
                        break;

                    default:
                        console.log('role not recognized.');
                        break;
                }
            }
        },
        cancelRecordDelete() {
            this.deleteConfirmModal = false;
            this.recordToDelete = null;
        },
        deleteRecord1(item) {
            this.recordToDelete = item;
            this.deleteConfirmModal = true;
        },
        submitDeleteRecord(id) {
            if (!this.$auth.role.includes('SuperAdmin')) {
                this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                return;
            }

            const found = this.results.find(x => x.id == id);

            this.loading = true;
            this.axiosInstance
                .delete(`/TributeVideo/changelog/delete-record/${this.tributeId}/${id}`)
                .then(resp => {
                    if (found) {
                        const index = this.results.indexOf(found);
                        this.results.splice(index, 1);
                    }
                    this.showSnackbar({ message: 'Changelog record deleted' });
                })
                .finally(() => {
                    this.deleteConfirmModal = false;
                    this.selectedRecord = null;
                    this.recordToDelete = null;
                    this.showRaw = false;
                    this.loading = false;
                });
        },
        filterDuplicatesById(arr) {
            const seenIds = new Set();
            return arr.filter(item => {
                if (seenIds.has(item.id)) {
                    return false;
                } else {
                    seenIds.add(item.id);
                    return true;
                }
            });
        },
        undoAllChanges() {
            this.loading = true;
            this.axiosInstance
                .post(`/TributeVideo/changelog/undo-all-since-render/${this.tributeId}`)
                .then(resp => {
                    this.recordToDelete = null;
                    this.continuationToken = null;

                    this.results = [];
                    this.getChangelogResults(true);

                    this.showSnackbar({ message: 'Undo successful' });
                    this.$emit('undo-all-success');
                })
                .catch(error => {
                    console.log(error, 'error @ undo');
                    this.showSnackbar({ message: 'Error occurred during undo', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                    // this.display = false;
                });
        },
        openModal() {
            this.display = true;
        },
        closeModal() {
            this.display = false;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
    },
};
</script>
<style lang="scss" scoped>
.rawJson {
    background-color: #4b4b4b;
    color: white !important;
}

.rendered-change {
    color: #bababa !important;
    .custom-pre-text {
        color: #bababa !important;
    }

    .panel-header {
        // border: 2px solid red !important
    }
}
</style>
