export const eventTypes = [
    {
        label: 'Streaming Overage',
        color: '#2196f3',
        value: 0,
        icon: 'fa-solid fa-signal-stream',
    },
    {
        label: 'Viewer Overage',
        color: 'teal',
        value: 1,
        icon: 'fa-solid fa-user',
    },
    {
        label: 'Service Consumption',
        color: '#3f51b5',
        value: 2,
        icon: 'fa-solid fa-calendar-day',
    },
    {
        label: 'Subscription Charge',
        color: 'purple',
        value: 3,
        icon: 'fa-solid fa-repeat',
    },
    {
        label: 'Onboarding',
        color: '#00C853',
        value: 4,
        icon: 'fa-solid fa-screen-users',
    },
    {
        label: 'Equipment',
        color: '#651FFF',
        value: 5,
        icon: 'fa-solid fa-toolbox',
    },
    {
        label: 'Custom Edit',
        color: '#ff530d',
        value: 6,
        icon: 'fa-solid fa-camera-movie',
    },
    {
        label: 'Products',
        color: '#975DBE',
        value: 7,
        icon: 'fa-solid fa-store',
    },
    {
        label: 'Misc',
        color: '#374550',
        value: 8,
        icon: 'fa-solid fa-badge-dollar',
    },
    {
        label: 'Tribute Video',
        color: '#ff9800',
        value: 9,
        icon: 'fa-regular fa-photo-film-music',
    },
];

export const serviceStates = [
    {
        definition: 'Created',
        label: 'Coming Up',
        color: '#2196F3',
        value: 0,
    },
    {
        definition: 'Coming Up',
        label: 'Coming Up',
        color: '#2196F3',
        value: 1,
    },
    {
        definition: 'Live',
        label: 'Live',
        color: 'rgb(255, 82, 82)',
        value: 2,
    },
    {
        definition: 'InBetween',
        label: 'In Progress',
        color: '#9C27B0',
        value: 3,
    },
    {
        definition: 'Finished',
        label: 'In Progress',
        color: '#9C27B0',
        value: 4,
    },
    {
        definition: 'Completed',
        label: 'Completed',
        color: '#4CAF50',
        value: 5,
    },
    {
        definition: 'Preview',
        label: 'In Progress',
        color: '#9C27B0',
        value: 6,
    },
    {
        definition: 'Paused',
        label: 'In Progress',
        color: '#9C27B0',
        value: 7,
    },
];

export const liveSuperAdminStates = [
    {
        value: 0,
        label: 'Created',
    },
    {
        value: 1,
        label: 'Streaming',
    },
    {
        value: 2,
        label: 'Paused',
    },
    {
        value: 3,
        label: 'Completed',
    },
];

export const subscriptionStatuses = [
    {
        label: 'Active',
        color: '#3E8F34',
        value: 0,
    },
    {
        label: 'Canceled',
        color: '#D03C3C',
        value: 1,
    },
    {
        label: 'Incomplete',
        color: '#CB912F',
        value: 2,
    },
    {
        label: 'Incomplete Expired',
        color: '#CB912F',
        value: 3,
    },
    {
        label: 'Trial',
        color: '#975DBE',
        value: 4,
    },
    {
        label: 'Past Due',
        color: '#D25216',
        value: 5,
    },
    {
        label: 'Unpaid',
        color: '#D25216',
        value: 6,
    },
    {
        label: 'No Card',
        color: '#D03C3C',
        value: 7,
    },
    {
        label: 'Dev',
        color: 'orange',
        value: 8,
    },
    {
        label: 'Cancellation Pending',
        color: '#ff6666',
        value: 9,
    },
    {
        label: 'Promo',
        color: 'cyan',
        value: 10,
    },
];

export const storageTypes = [
    { label: 'Hot', value: 0, color: '' },
    { label: 'Cool', value: 1, color: '' },
    { label: 'Cold', value: 2, color: '' },
    { label: 'Archive', value: 3, color: '' },
];

export const tributeRenderTypes = [
    { label: 'Original', value: 0, color: '#2196F3' },
    { label: 'Public', value: 1, color: '#06AA58' },
];

export const copyrightStatuses = [
    { label: 'Unknown', value: 0, color: '#975DBE' },
    { label: 'Public Domain', value: 1, color: '#06AA58' },
    { label: 'Copyrighted', value: 2, color: '#D03C3C' },
    { label: 'Licensed', value: 3, color: '#D03C3C' },
    { label: 'Expired', value: 4, color: '#2196F3' },
    { label: 'Requires Review', value: 5, color: '#ff6666' },
];

export const streamStates = ['Created', 'Streaming', 'Paused', 'Live', 'Completed'];

export const embedCodeTypes = ['Code', 'Link', 'Button', 'Iframe'];

export const eventStates = [
    {
        label: 'Created',
        color: '#2196F3',
        value: 0,
    },
    {
        label: 'Preview',
        color: '#2196F3',
        value: 1,
    },
    {
        label: 'Live',
        color: 'rgb(255, 82, 82)',
        value: 2,
    },
    {
        label: 'Finished',
        color: '#9C27B0',
        value: 3,
    },
    {
        label: 'Processing',
        color: '#cb912f',
        value: 4,
    },
    {
        label: 'Ready',
        color: '#4CAF50',
        value: 5,
    },
    {
        label: 'Paused',
        color: '#D25216',
        value: 6,
    },
    {
        label: 'Completed',
        color: '#3E8F34',
        value: 7,
    },
];

export const liveArchiveStatuses = [
    { label: 'Legacy', value: 0, color: '#975DBE' },
    { label: 'Created', value: 1, color: 'primary' },
    { label: 'Uploaded', value: 2, color: 'teal' },
    { label: 'Rendering', value: 3, color: 'orange' },
    { label: 'Archived', value: 4, color: '#06AA58' },
    { label: 'Bad Stream', value: 5, color: '#ff5252' },
    { label: 'Error', value: 6, color: '#ff5252' },
    { label: 'Render Error', value: 7, color: '#ff5252' },
    { label: 'Manual Review', value: 8, color: '#cb912f' },
    { label: 'No Stream', value: 9, color: 'black' },
];

export const hlsStatuses = [
    { label: 'Created', value: 0, color: '#0653aa' },
    { label: 'Uploaded', value: 1, color: '#0653aa' },
    { label: 'Processing', value: 2, color: 'orange' },
    { label: 'Transcoding', value: 3, color: 'orange' },
    { label: 'Finished', value: 4, color: 'success' },
    { label: 'Error', value: 5, color: '#ff5252' },
    { label: 'Upload Failed', value: 6, color: '#ff5252' },
    { label: 'Transcribing', value: 7, color: 'orange' },
];

export const playbackTypes = [
    { label: 'Converted', color: '#0653aa', value: 0 },
    { label: 'Converted + Hls', color: 'orange', value: 1 },
    { label: 'Hls', color: 'black', value: 2 },
];

export const renderEngineTypes = [
    { label: 'Standard', color: '', value: 0 },
    { label: 'Archive', color: '#0653aa', value: 1 },
    { label: 'HLS', color: '#975DBE', value: 2 },
    { label: 'Audio', color: '#2196F3', value: 3 },
];

export const renderSpeeds = [
    { label: 'Fast', color: 'orange', value: 0 },
    { label: 'Slow', color: '#0653aa', value: 1 },
    { label: 'Debug', color: '#700070', value: 2 },
];

export const streamIssues = [
    {
        title: 'Education',
        value: 0,
        text: 'Education',
        color: '#AAAA06',
        virtual: true,
        hidden: false,
        locked: false,
    },
    {
        title: 'Mobile App',
        value: 1,
        text: 'Mobile App',
        color: '#ff530D',
        virtual: true,
        hidden: false,
        locked: false,
    },
    {
        title: 'Wowza',
        value: 2,
        text: 'Wowza',
        color: '#700070',
        virtual: true,
        hidden: false,
        locked: false,
    },
    {
        title: 'Web App',
        value: 3,
        text: 'Web App',
        color: '#E00000',
        virtual: true,
        hidden: false,
        locked: false,
    },
    {
        title: 'Success',
        value: 4,
        text: 'Success',
        color: '#06AA58',
        virtual: true,
        hidden: false,
        locked: false,
    },
    {
        title: 'Did Not Stream',
        value: 5,
        text: 'Did Not Stream',
        color: '#0653aa',
        virtual: true,
        hidden: false,
        locked: false,
    },
    {
        title: 'Connection',
        value: 6,
        text: 'Connection',
        color: 'cyan',
        virtual: true,
        hidden: false,
        locked: false,
    },
    {
        title: 'No Status',
        value: null,
        text: 'No Status',
        color: '#0653aa',
        virtual: true,
        hidden: false,
        locked: false,
    },
];

export const emailJobStatuses = [
    { label: 'Created', color: '#0653aa', value: 0 },
    { label: 'Success', color: '#06AA58', value: 1 },
    { label: 'Error', color: '#ff5252', value: 2 },
    { label: 'Scheduled', color: '#975DBE', value: 3 },
];

export const emailJobTypes = [
    { label: 'FH Summary', color: '#0653aa', value: 0 },
    { label: 'Service Summary', color: '#975DBE', value: 1 },
];

export const accountChanges = [
    {
        label: 'Start',
        color: 'blue',
    },
    {
        label: 'Cancel',
        color: 'red',
    },
    {
        label: 'Upgrade',
        color: 'green',
    },
    {
        label: 'Downgrade',
        color: 'yellow',
    },
];

export const paymentStatuses = [
    {
        label: 'Unpaid',
        color: 'red',
    },
    {
        label: 'Paid',
        color: 'green',
    },
    {
        label: 'Refunded',
        color: 'orange',
    },
];

export const productStatuses = [
    {
        label: 'In Review',
        color: 'orange',
        value: 0,
    },
    {
        label: 'Active',
        color: 'green',
        value: 1,
    },
    {
        label: 'Canceled',
        color: 'red',
        value: 2,
    },
];

export const productCategories = [
    {
        label: 'Other',
        color: 'orange',
        value: 0,
    },
    {
        label: 'Keepsake',
        color: 'green',
        value: 1,
    },
    {
        label: 'Media',
        color: 'blue',
        value: 2,
    },
];

export const orderStatuses = [
    {
        label: 'Open',
        color: 'blue',
        value: 0,
    },
    {
        label: 'Complete',
        color: 'green',
        value: 1,
    },
    {
        label: 'Canceled',
        color: 'red',
        value: 2,
    },
    {
        label: 'Returned',
        color: 'orange',
        value: 3,
    },
];

export const userTypes = [
    { label: 'Funeral Home', value: 0, color: 'teal' },
    { label: 'Owner', value: 1, color: '#2196f3' },
    { label: 'Super Admin', value: 2, color: 'black' },
    { label: 'Supplier', value: 3, color: 'orange' },
    { label: 'Regional Manager', value: 4, color: '#00C853' },
];

export const phoneTypes = [
    { label: 'Unknown', value: 0, color: '#700070' },
    { label: 'Mobile', value: 1, color: '#4caf50' },
    { label: 'Landline', value: 2, color: '#ff5252' },
    { label: 'Landline Confirmed', value: 3, color: '#ff5252' },
    { label: 'Mobile (Override)', value: 4, color: '#4caf50' },
];

export const usageRecordTypes = [
    { label: 'Legacy', value: 0, color: '#651FFF' },
    { label: 'Billable', value: 1, color: '#00C853' },
    { label: 'User', value: 2, color: '#2196f3' },
];

export const moderatedStatuses = [
    { label: 'Unknown', value: 0, color: 'black' },
    { label: 'Safe', value: 1, color: '#4caf50' },
    { label: 'Spam', value: 2, color: 'orange' },
    { label: 'Offensive', value: 3, color: '#ff5252' },
    { label: 'Questionable', value: 4, color: '#700070' },
];

export const renderTypes = [
    { label: 'Full W/ Slate', color: '#975DBE', value: 0 },
    { label: 'Full No Slate', color: 'teal', value: 1 },
    { label: 'Strip Slate', color: 'indigo', value: 2 },
    { label: 'Add Slate', color: 'orange', value: 3 },
    { label: 'Re-Edit Slate', color: 'gray', value: 4 },
    { label: 'Edit Video Slate', color: 'green', value: 5 },
    { label: 'Tribute', color: 'cyan', value: 6 },
    { label: 'Re-Render W/ Slate', color: '#D25216', value: 7 },
    { label: 'Re-Render W/o Slate', color: '#cb912f', value: 8 },
    { label: 'Wowza', color: 'orange', value: 9 },
    { label: 'Tribute Standard Audio', color: 'black', value: 10 },
    { label: 'Tribute Copyright Audio', color: 'black', value: 11 },
];

export const queStatusEnum = [
    { label: 'Created', color: '#2196f3', value: 0 },
    { label: 'VM Started', color: '#975DBE', value: 1 },
    { label: 'Uploading To Azure Render Farm', color: 'orange', value: 2 },
    { label: 'Uploaded To Azure Render Farm', color: 'indigo', value: 3 },
    { label: 'In Vm Que', color: 'gray', value: 4 },
    { label: 'Downloading', color: 'purple', value: 5 },
    { label: 'Downloaded', color: '#cb912f', value: 6 },
    { label: 'Rendering', color: '#054A59', value: 7 },
    { label: 'Uploading Converted File', color: 'cyan', value: 8 },
    { label: 'Finished', color: 'green', value: 9 },
    { label: 'Error', color: 'red', value: 10 },
    { label: 'Processing Audio', color: '#1db954', value: 11 },
];

// Ensure only hex code colors used here, see  rgbaColor() in ChangelogPanel.vue
export const tributeChangelogActions = [
    { label: 'Photo Add', value: 0, color: '#4caf50', icon: 'fa-regular fa-plus' },
    { label: 'Photo Delete', value: 1, color: '#ff5252', icon: 'fa-regular fa-trash-can' },
    { label: 'Photo Reorder', value: 2, color: '#fb8c00', icon: 'fa-regular fa-arrow-right' },
    { label: 'Photo Set Reorder', value: 3, color: '#fb8c00', icon: 'fa-regular fa-arrow-right' },
    { label: 'Photo Edit', value: 4, color: '#fb8c00', icon: 'fa-regular fa-pencil' },
    { label: 'Photo Revert Edit', value: 5, color: '#fb8c00', icon: 'fa-regular fa-pencil' },
    { label: 'Mute Toggle', value: 6, color: '#fb8c00', icon: 'fa-regular fa-volume' },
    { label: 'Render', value: 7, color: 'black', icon: 'fa-regular fa-rotate' },
    { label: 'Profile Photo Update', value: 8, color: '#fb8c00', icon: 'fa-regular fa-pencil' },
    { label: 'Theme Update', value: 9, color: '#fb8c00', icon: 'fa-regular fa-table-layout' },
    { label: 'Song Select', value: 10, color: '#fb8c00', icon: 'fa-regular fa-pencil' },
    { label: 'Song Deselect', value: 11, color: '#fb8c00', icon: 'fa-regular fa-pencil' },
    { label: 'Song Reorder', value: 12, color: '#fb8c00', icon: 'fa-regular fa-arrow-right' },
    { label: 'Render Settings', value: 13, color: '#fb8c00', icon: 'fa-regular fa-gear' },
    { label: 'Render Submit', value: 14, color: '#000000', icon: 'fa-regular fa-rotate' },
    { label: 'Render Success', value: 15, color: '#4caf50', icon: 'fa-regular fa-check' },
    { label: 'Render Error', value: 16, color: '#ff5252', icon: 'fa-regular fa-triangle-exclamation' },
];

export const usStates = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaksa', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];

export const carriers = [
    { label: 'USPS', value: 0 },
    { label: 'UPS', value: 1 },
    { label: 'FedEx', value: 2 },
    { label: 'DHL', value: 3 },
];

export const shipmentStatuses = [
    { label: 'Created', value: 0, color: 'blue' },
    { label: 'In Transit', value: 1, color: 'indigo' },
    { label: 'Out For Delivery', value: 2, color: 'purple' },
    { label: 'Delivered', value: 3, color: 'green' },
    { label: 'Returned', value: 4, color: 'orange' },
    { label: 'Delivery Failed', value: 5, color: 'red' },
];

export const CUSTOMER_TYPE_FAMILY = {
    label: 'Family',
    value: 0,
    color: 'teal',
    icon: 'fa-solid fa-family',
};

export const CUSTOMER_TYPE_FUNERAL_HOME = {
    label: 'Funeral Home',
    value: 1,
    color: 'purple',
    icon: 'fa-solid fa-house',
};

export const customerTypes = [CUSTOMER_TYPE_FAMILY, CUSTOMER_TYPE_FUNERAL_HOME];

export const cloudStreamingProviders = [
    { label: 'Wowza', value: 0, logo: 'wowza-orange.png' },
    { label: 'Mux', value: 1, logo: 'mux.png' },
];

export const streamingProviders = [
    { label: 'Xsplit', value: 0 },
    { label: 'Larix', value: 1 },
    { label: 'MWS App', value: 2 },
    { label: 'RTMP', value: 3 },
    { label: 'Teradek', value: 4 },
    { label: 'RTSP', value: 5 },
    { label: 'WebRTC', value: 6 },
];

export const trackingCodeTypes = [
    { label: 'Facebook', value: 0, logo: 'facebook.png' },
    { label: 'Google Analytics', value: 1, logo: 'google_analytics.png' },
    { label: 'Google Ads', value: 2, logo: 'google_ads.png' },
    { label: 'Google Tag', value: 3, logo: 'google_tag.png' },
    { label: 'TikTok', value: 4, logo: 'tiktok.png' },
    { label: 'Twitter', value: 5, logo: 'twitter.png' },
    { label: 'LinkedIn', value: 6, logo: 'linkedIn.png' },
    { label: 'Other', value: 7, logo: 'logo.png' },
];

export const trackingCodeTriggers = [
    { label: 'Page Load', icon: 'fa-solid fa-loader', value: 0 },
    { label: 'Play', icon: 'fa-solid fa-play', value: 1 },
    { label: 'Watched', icon: 'fa-solid fa-projector', value: 2 },
];

export const templateDynamicElements = [
    { label: 'Text', value: 0 },
    { label: 'Image', value: 1 },
    { label: 'Video', value: 2 },
    { label: 'Audio', value: 3 },
];

export const creatomateRenderStatuses = [
    { label: 'Planned', value: 0, color: '#2196F3' },
    { label: 'Rendering', value: 1, color: 'orange' },
    { label: 'Succeeded', value: 2, color: '#06AA58' },
    { label: 'Failed', value: 3, color: '#ff5252' },
];

export const tributeTemplateStatuses = [
    { label: 'Created', value: 0, color: '#2196F3' },
    { label: 'QA', value: 1, color: 'orange' },
    { label: 'Ready For Prod', value: 2, color: '#9031aa' },
    { label: 'In Production', value: 3, color: '#06AA58' },
];

export const tributeStatuses = [
    { label: 'Created', value: 0, color: '#2196F3' },
    { label: 'In Progress', value: 1, color: 'purple' },
    { label: 'Previewing', value: 2, color: 'teal' },
    { label: 'Waiting For Photos', value: 3, color: '#0653aa' },
    { label: 'Rendering', value: 4, color: 'orange' },
    { label: 'Completed', value: 5, color: '#06AA58' },
    { label: 'Error', value: 6, color: '#ff5252' },
];

export const uploadSources = [
    { label: 'Web App', value: 0 },
    { label: 'Mobile', value: 1 },
    { label: 'Anonymous', value: 2 },
];

export const licenseConfidences = [
    { label: 'Unknown', value: 0, color: 'black' },
    { label: 'Bad', value: 1, color: '#ff5252' },
    { label: 'Grey', value: 2, color: '#999' },
    { label: 'Good', value: 3, color: '#06AA58' },
    { label: 'Limited', value: 4, color: '#FFA500' },
    { label: 'Unrestricted', value: 5, color: '#2196F3' },
];

export const boolSelectItems = [
    { label: 'True', value: true },
    { label: 'False', value: false },
];

export const betaFeaturesEnum = {
    NONE: 0,
    ALL: 1,
};

export const betaFeaturesDetails = {
    [betaFeaturesEnum.NONE]: {
        label: 'None',
        value: betaFeaturesEnum.NONE,
    },
    [betaFeaturesEnum.ALL]: {
        label: 'All',
        value: betaFeaturesEnum.ALL,
    },
};

export const getBetaFeaturesDetails = value => {
    return betaFeaturesDetails[value] || null;
};

export const betaFeaturesOptions = Object.values(betaFeaturesDetails);

export const MEDIA_UPLOADER_TYPES = {
    STORY_PROFILE_PICTURE: 0,
    TRIBUTE_ENDING_BG: 1,
    MUSIC_GENRE_IMAGE: 2,
    TRIBUTE_VIDEO_SONG_ARTWORK: 3,
    TRIBUTE_VIDEO_SONG_LICENSE: 4,
    TRIBUTE_VIDEO_ENDING_VIDEO: 5,
    TRIBUTE_SONG_GENRE: 6,
};

export const getUploaderEndpoints = mediaUploaderType => {
    const endpoints = {
        [MEDIA_UPLOADER_TYPES.STORY_PROFILE_PICTURE]: {
            sas: 'Story/profile-picture/sas',
            update: 'Story/profile-picture/update',
        },
        [MEDIA_UPLOADER_TYPES.TRIBUTE_ENDING_BG]: {
            sas: 'Funeralhomes/tribute-ending-bg/sas',
            update: 'Funeralhomes/tribute-ending-bg',
        },
        [MEDIA_UPLOADER_TYPES.MUSIC_GENRE_IMAGE]: {
            sas: 'MusicGenre/image/sas',
            update: 'MusicGenre/image',
        },
        [MEDIA_UPLOADER_TYPES.TRIBUTE_VIDEO_SONG_ARTWORK]: {
            sas: 'TributeVideoSong/artwork/sas',
            update: 'TributeVideoSong/artwork',
        },
        [MEDIA_UPLOADER_TYPES.TRIBUTE_VIDEO_SONG_LICENSE]: {
            sas: 'TributeVideoSong/song-license/sas',
            update: 'TributeVideoSong/song-license',
        },
        [MEDIA_UPLOADER_TYPES.TRIBUTE_VIDEO_ENDING_VIDEO]: {
            sas: 'Funeralhomes/tribute-ending-video/sas',
            update: 'Funeralhomes/tribute-ending-video',
        },
        [MEDIA_UPLOADER_TYPES.TRIBUTE_SONG_GENRE]: {
            sas: 'MusicGenre/image/sas',
            update: 'MusicGenre/image',
        },
    };
    return endpoints[mediaUploaderType] || null;
};

export const countries = [
    { name: 'United States', abbreviation: 'US' },
    { name: 'Canada', abbreviation: 'CA' },
    { name: 'Afghanistan', abbreviation: 'AF' },
    { name: 'Albania', abbreviation: 'AL' },
    { name: 'Algeria', abbreviation: 'DZ' },
    { name: 'Andorra', abbreviation: 'AD' },
    { name: 'Angola', abbreviation: 'AO' },
    { name: 'Antigua and Barbuda', abbreviation: 'AG' },
    { name: 'Argentina', abbreviation: 'AR' },
    { name: 'Armenia', abbreviation: 'AM' },
    { name: 'Australia', abbreviation: 'AU' },
    { name: 'Austria', abbreviation: 'AT' },
    { name: 'Azerbaijan', abbreviation: 'AZ' },
    { name: 'Bahamas', abbreviation: 'BS' },
    { name: 'Bahrain', abbreviation: 'BH' },
    { name: 'Bangladesh', abbreviation: 'BD' },
    { name: 'Barbados', abbreviation: 'BB' },
    { name: 'Belarus', abbreviation: 'BY' },
    { name: 'Belgium', abbreviation: 'BE' },
    { name: 'Belize', abbreviation: 'BZ' },
    { name: 'Benin', abbreviation: 'BJ' },
    { name: 'Bhutan', abbreviation: 'BT' },
    { name: 'Bolivia', abbreviation: 'BO' },
    { name: 'Bosnia and Herzegovina', abbreviation: 'BA' },
    { name: 'Botswana', abbreviation: 'BW' },
    { name: 'Brazil', abbreviation: 'BR' },
    { name: 'Brunei', abbreviation: 'BN' },
    { name: 'Bulgaria', abbreviation: 'BG' },
    { name: 'Burkina Faso', abbreviation: 'BF' },
    { name: 'Burundi', abbreviation: 'BI' },
    { name: 'Cabo Verde', abbreviation: 'CV' },
    { name: 'Cambodia', abbreviation: 'KH' },
    { name: 'Cameroon', abbreviation: 'CM' },
    { name: 'Central African Republic', abbreviation: 'CF' },
    { name: 'Chad', abbreviation: 'TD' },
    { name: 'Chile', abbreviation: 'CL' },
    { name: 'China', abbreviation: 'CN' },
    { name: 'Colombia', abbreviation: 'CO' },
    { name: 'Comoros', abbreviation: 'KM' },
    { name: 'Congo', abbreviation: 'CG' },
    { name: 'Costa Rica', abbreviation: 'CR' },
    { name: "Côte d'Ivoire", abbreviation: 'CI' },
    { name: 'Croatia', abbreviation: 'HR' },
    { name: 'Cuba', abbreviation: 'CU' },
    { name: 'Cyprus', abbreviation: 'CY' },
    { name: 'Czech Republic', abbreviation: 'CZ' },
    { name: 'Democratic Republic of the Congo', abbreviation: 'CD' },
    { name: 'Denmark', abbreviation: 'DK' },
    { name: 'Djibouti', abbreviation: 'DJ' },
    { name: 'Dominica', abbreviation: 'DM' },
    { name: 'Dominican Republic', abbreviation: 'DO' },
    { name: 'Ecuador', abbreviation: 'EC' },
    { name: 'Egypt', abbreviation: 'EG' },
    { name: 'El Salvador', abbreviation: 'SV' },
    { name: 'Equatorial Guinea', abbreviation: 'GQ' },
    { name: 'Eritrea', abbreviation: 'ER' },
    { name: 'Estonia', abbreviation: 'EE' },
    { name: 'Eswatini', abbreviation: 'SZ' },
    { name: 'Ethiopia', abbreviation: 'ET' },
    { name: 'Fiji', abbreviation: 'FJ' },
    { name: 'Finland', abbreviation: 'FI' },
    { name: 'France', abbreviation: 'FR' },
    { name: 'Gabon', abbreviation: 'GA' },
    { name: 'Gambia', abbreviation: 'GM' },
    { name: 'Georgia', abbreviation: 'GE' },
    { name: 'Germany', abbreviation: 'DE' },
    { name: 'Ghana', abbreviation: 'GH' },
    { name: 'Greece', abbreviation: 'GR' },
    { name: 'Grenada', abbreviation: 'GD' },
    { name: 'Guatemala', abbreviation: 'GT' },
    { name: 'Guinea', abbreviation: 'GN' },
    { name: 'Guinea-Bissau', abbreviation: 'GW' },
    { name: 'Guyana', abbreviation: 'GY' },
    { name: 'Haiti', abbreviation: 'HT' },
    { name: 'Holy See', abbreviation: 'VA' },
    { name: 'Honduras', abbreviation: 'HN' },
    { name: 'Hungary', abbreviation: 'HU' },
    { name: 'Iceland', abbreviation: 'IS' },
    { name: 'India', abbreviation: 'IN' },
    { name: 'Indonesia', abbreviation: 'ID' },
    { name: 'Iran', abbreviation: 'IR' },
    { name: 'Iraq', abbreviation: 'IQ' },
    { name: 'Ireland', abbreviation: 'IE' },
    { name: 'Israel', abbreviation: 'IL' },
    { name: 'Italy', abbreviation: 'IT' },
    { name: 'Jamaica', abbreviation: 'JM' },
    { name: 'Japan', abbreviation: 'JP' },
    { name: 'Jordan', abbreviation: 'JO' },
    { name: 'Kazakhstan', abbreviation: 'KZ' },
    { name: 'Kenya', abbreviation: 'KE' },
    { name: 'Kiribati', abbreviation: 'KI' },
    { name: 'Korea', abbreviation: 'KR' },
    { name: 'Korea, North', abbreviation: 'KP' },
    { name: 'Kosovo', abbreviation: 'XK' },
    { name: 'Kuwait', abbreviation: 'KW' },
    { name: 'Kyrgyzstan', abbreviation: 'KG' },
    { name: 'Laos', abbreviation: 'LA' },
    { name: 'Latvia', abbreviation: 'LV' },
    { name: 'Lebanon', abbreviation: 'LB' },
    { name: 'Lesotho', abbreviation: 'LS' },
    { name: 'Liberia', abbreviation: 'LR' },
    { name: 'Libya', abbreviation: 'LY' },
    { name: 'Liechtenstein', abbreviation: 'LI' },
    { name: 'Lithuania', abbreviation: 'LT' },
    { name: 'Luxembourg', abbreviation: 'LU' },
    { name: 'Madagascar', abbreviation: 'MG' },
    { name: 'Malawi', abbreviation: 'MW' },
    { name: 'Malaysia', abbreviation: 'MY' },
    { name: 'Maldives', abbreviation: 'MV' },
    { name: 'Mali', abbreviation: 'ML' },
    { name: 'Malta', abbreviation: 'MT' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Mauritania', abbreviation: 'MR' },
    { name: 'Mauritius', abbreviation: 'MU' },
    { name: 'Mexico', abbreviation: 'MX' },
    { name: 'Micronesia', abbreviation: 'FM' },
    { name: 'Moldova', abbreviation: 'MD' },
    { name: 'Monaco', abbreviation: 'MC' },
    { name: 'Mongolia', abbreviation: 'MN' },
    { name: 'Montenegro', abbreviation: 'ME' },
    { name: 'Morocco', abbreviation: 'MA' },
    { name: 'Mozambique', abbreviation: 'MZ' },
    { name: 'Myanmar', abbreviation: 'MM' },
    { name: 'Namibia', abbreviation: 'NA' },
    { name: 'Nauru', abbreviation: 'NR' },
    { name: 'Nepal', abbreviation: 'NP' },
    { name: 'Netherlands', abbreviation: 'NL' },
    { name: 'New Zealand', abbreviation: 'NZ' },
    { name: 'Nicaragua', abbreviation: 'NI' },
    { name: 'Niger', abbreviation: 'NE' },
    { name: 'Nigeria', abbreviation: 'NG' },
    { name: 'North Macedonia', abbreviation: 'MK' },
    { name: 'Norway', abbreviation: 'NO' },
    { name: 'Oman', abbreviation: 'OM' },
    { name: 'Pakistan', abbreviation: 'PK' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Palestine', abbreviation: 'PS' },
    { name: 'Panama', abbreviation: 'PA' },
    { name: 'Papua New Guinea', abbreviation: 'PG' },
    { name: 'Paraguay', abbreviation: 'PY' },
    { name: 'Peru', abbreviation: 'PE' },
    { name: 'Philippines', abbreviation: 'PH' },
    { name: 'Poland', abbreviation: 'PL' },
    { name: 'Portugal', abbreviation: 'PT' },
    { name: 'Qatar', abbreviation: 'QA' },
    { name: 'Romania', abbreviation: 'RO' },
    { name: 'Russia', abbreviation: 'RU' },
    { name: 'Rwanda', abbreviation: 'RW' },
    { name: 'Saint Kitts and Nevis', abbreviation: 'KN' },
    { name: 'Saint Lucia', abbreviation: 'LC' },
    { name: 'Saint Vincent and the Grenadines', abbreviation: 'VC' },
    { name: 'Samoa', abbreviation: 'WS' },
    { name: 'San Marino', abbreviation: 'SM' },
    { name: 'Sao Tome and Principe', abbreviation: 'ST' },
    { name: 'Saudi Arabia', abbreviation: 'SA' },
    { name: 'Senegal', abbreviation: 'SN' },
    { name: 'Serbia', abbreviation: 'RS' },
    { name: 'Seychelles', abbreviation: 'SC' },
    { name: 'Sierra Leone', abbreviation: 'SL' },
    { name: 'Singapore', abbreviation: 'SG' },
    { name: 'Slovakia', abbreviation: 'SK' },
    { name: 'Slovenia', abbreviation: 'SI' },
    { name: 'Solomon Islands', abbreviation: 'SB' },
    { name: 'Somalia', abbreviation: 'SO' },
    { name: 'South Africa', abbreviation: 'ZA' },
    { name: 'South Sudan', abbreviation: 'SS' },
    { name: 'Spain', abbreviation: 'ES' },
    { name: 'Sri Lanka', abbreviation: 'LK' },
    { name: 'Sudan', abbreviation: 'SD' },
    { name: 'Suriname', abbreviation: 'SR' },
    { name: 'Sweden', abbreviation: 'SE' },
    { name: 'Switzerland', abbreviation: 'CH' },
    { name: 'Syria', abbreviation: 'SY' },
    { name: 'Taiwan', abbreviation: 'TW' },
    { name: 'Tajikistan', abbreviation: 'TJ' },
    { name: 'Tanzania', abbreviation: 'TZ' },
    { name: 'Thailand', abbreviation: 'TH' },
    { name: 'Timor-Leste', abbreviation: 'TL' },
    { name: 'Togo', abbreviation: 'TG' },
    { name: 'Tonga', abbreviation: 'TO' },
    { name: 'Trinidad and Tobago', abbreviation: 'TT' },
    { name: 'Tunisia', abbreviation: 'TN' },
    { name: 'Turkey', abbreviation: 'TR' },
    { name: 'Turkmenistan', abbreviation: 'TM' },
    { name: 'Tuvalu', abbreviation: 'TV' },
    { name: 'Uganda', abbreviation: 'UG' },
    { name: 'Ukraine', abbreviation: 'UA' },
    { name: 'United Arab Emirates', abbreviation: 'AE' },
    { name: 'United Kingdom', abbreviation: 'GB' },
    { name: 'Uruguay', abbreviation: 'UY' },
    { name: 'Uzbekistan', abbreviation: 'UZ' },
    { name: 'Vanuatu', abbreviation: 'VU' },
    { name: 'Venezuela', abbreviation: 'VE' },
    { name: 'Vietnam', abbreviation: 'VN' },
    { name: 'Yemen', abbreviation: 'YE' },
    { name: 'Zambia', abbreviation: 'ZM' },
    { name: 'Zimbabwe', abbreviation: 'ZW' },
];

export const candaianProvinces = [
    { name: 'Alberta', abbreviation: 'AB' },
    { name: 'British Columbia', abbreviation: 'BC' },
    { name: 'Manitoba', abbreviation: 'MB' },
    { name: 'New Brunswick', abbreviation: 'NB' },
    { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
    { name: 'Nova Scotia', abbreviation: 'NS' },
    { name: 'Ontario', abbreviation: 'ON' },
    { name: 'Prince Edward Island', abbreviation: 'PE' },
    { name: 'Quebec', abbreviation: 'QC' },
    { name: 'Saskatchewan', abbreviation: 'SK' },
    { name: 'Northwest Territories', abbreviation: 'NT' },
    { name: 'Nunavut', abbreviation: 'NU' },
    { name: 'Yukon', abbreviation: 'YT' },
];

//Memoryshare Standard = random
export const transitionTypes = [
    {
        label: 'MemoryShare Standard',
        value: 0,
    },
    {
        label: 'Fade',
        value: 1,
    },
    {
        label: 'Slide Left',
        value: 2,
    },
    {
        label: 'Slide Right',
        value: 3,
    },
    {
        label: 'Slide Up',
        value: 4,
    },
    {
        label: 'Slide Down',
        value: 5,
    },
    {
        label: 'Slide Left Up',
        value: 6,
    },
    {
        label: 'Slide Left Down',
        value: 7,
    },
    {
        label: 'Slide Right Up',
        value: 8,
    },
    {
        label: 'Slide Right Down',
        value: 9,
    },
    {
        label: 'Rotate Left',
        value: 10,
    },
    {
        label: 'Rotate Right',
        value: 11,
    },
    {
        label: 'Rotate Up',
        value: 12,
    },
    {
        label: 'Rotate Down',
        value: 13,
    },
    {
        label: 'Wipe Left',
        value: 14,
    },
    {
        label: 'Wipe Right',
        value: 15,
    },
    {
        label: 'Wipe Up',
        value: 16,
    },
    {
        label: 'Wipe Down',
        value: 17,
    },
    {
        label: 'Wipe X-Axis',
        value: 18,
    },
    {
        label: 'Wipe Y-Axis',
        value: 19,
    },
    {
        label: 'Wipe Diagonal',
        value: 20,
    },
    {
        label: 'Wipe Rotating',
        value: 21,
    },
    {
        label: 'Circular Wipe Left',
        value: 22,
    },
    {
        label: 'Circular Wipe Right',
        value: 23,
    },
    {
        label: 'Circular Wipe Up',
        value: 24,
    },
    {
        label: 'Circular Wipe Down',
        value: 25,
    },
    {
        label: 'Circular Wipe Center',
        value: 26,
    },
    {
        label: 'Circular Wipe Shockwave',
        value: 27,
    },
    {
        label: 'Color Wipe Left',
        value: 28,
    },
    {
        label: 'Color Wipe Right',
        value: 29,
    },
    {
        label: 'Color Wipe Up',
        value: 30,
    },
    {
        label: 'Color Wipe Down',
        value: 31,
    },
    {
        label: 'Squash Left',
        value: 32,
    },
    {
        label: 'Squash Right',
        value: 33,
    },
    {
        label: 'Squash Up',
        value: 34,
    },
    {
        label: 'Squash Down',
        value: 35,
    },
    {
        label: 'Spin Clockwise',
        value: 36,
    },
    {
        label: 'Spin Counter Clockwise',
        value: 37,
    },
    {
        label: 'Spin Hinge Left',
        value: 38,
    },
    {
        label: 'Spin Hinge Right',
        value: 39,
    },
    {
        label: 'Flip Left',
        value: 40,
    },
    {
        label: 'Flip Right',
        value: 41,
    },
    {
        label: 'Flip Up',
        value: 42,
    },
    {
        label: 'Flip Down',
        value: 43,
    },
    {
        label: 'Flip Left Up',
        value: 44,
    },
    {
        label: 'Flip Left Down',
        value: 45,
    },
    {
        label: 'Flip Right Up',
        value: 46,
    },
    {
        label: 'Flip Right Down',
        value: 47,
    },
    {
        label: 'Flip Page',
        value: 48,
    },
    {
        label: 'Film Roll Left',
        value: 49,
    },
    {
        label: 'Film Roll Right',
        value: 50,
    },
    {
        label: 'Film Roll Up',
        value: 51,
    },
    {
        label: 'Film Roll Down',
        value: 52,
    },
    {
        label: 'Scale',
        value: 53,
    },
    {
        label: 'Scale Larger',
        value: 54,
    },
    {
        label: 'Scale Left',
        value: 55,
    },
    {
        label: 'Scale Right',
        value: 56,
    },
    {
        label: 'Scale Up',
        value: 57,
    },
    {
        label: 'Scale Down',
        value: 58,
    },
    {
        label: 'Scale Left Up',
        value: 59,
    },
    {
        label: 'Scale Left Down',
        value: 60,
    },
    {
        label: 'Scale Right Up',
        value: 61,
    },
    {
        label: 'Scale Right Down',
        value: 62,
    },
    {
        label: 'Scale X Axis Left',
        value: 63,
    },
    {
        label: 'Scale X Axis Right',
        value: 64,
    },
    {
        label: 'Scale Y Axis Up',
        value: 65,
    },
    {
        label: 'Scale Y Axis Down',
        value: 66,
    },
];

export const imglyCardBgAssets = [
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/floral1.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/floral1.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/floral2.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/floral2.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/floral3.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/floral3.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/floral4.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/floral4.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/floral5.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/floral5.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/grass1.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/grass1.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/grass2.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/grass2.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/clouds1.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/clouds1.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/clouds2.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/clouds2.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/forrest-bridge.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/forrest-bridge.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/forrest.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/forrest.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/hills1.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/hills1.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/road.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/road.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/texture.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/texture.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/sky1.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/sky1.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/book1.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/book1.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/leaves1.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/leaves1.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/leaves2.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/leaves2.jpg',
    },
    // {
    //     link: 'https://videos.memoryshare.com/imgly-card-backgrounds/water1.jpg',
    //     thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/water1.jpg',
    // },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/water2.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/water2.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/water3.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/water3.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/beach.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/beach.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/gradient1.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/gradient1.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/gradient2.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/gradient2.jpg',
    },
    {
        link: 'https://videos.memoryshare.com/imgly-card-backgrounds/gradient3.jpg',
        thumbnail: 'https://photos.memoryshare.com/imgly-assets/tr:w-250,h-250/gradient3.jpg',
    },
];
