// =============================================================================
// CORE
// =============================================================================
import Vue from 'vue';
import VueRouter from 'vue-router';
import { authGuard } from '../auth/authGuard';
Vue.use(VueRouter);

// =============================================================================
// ANONYMOUS
// =============================================================================
import AnonTributePage from '@/views/Anonymous/AnonTributePage.vue';
import TributeStoriesContribute from '@/views/Anonymous/TributeStoriesContribute.vue';
import LandingPage from '@/views/Anonymous/LandingPage.vue';
import ViewStory from '@/views/Anonymous/ViewStory.vue';
import ViewStoryQr from '@/views/Anonymous/ViewStoryQr.vue';

// =============================================================================
// ANALYTICS
// =============================================================================
import Analytics from '../views/Analytics/Analytics.vue';

// =============================================================================
// ADMIN
// =============================================================================
import AdminBilling from '../views/Admin/AdminBilling.vue';
import AdminSettings from '../views/Admin/AdminSettings.vue';
import DailyStreamSheet from '../views/Admin/DailyStreamSheet.vue';
import HomesList from '../views/Admin/HomesList.vue';
import HomesByOwner from '../views/Admin/HomesByOwner.vue';
import ManageAdminUsers from '../views/Admin/ManageAdminUsers';
import CreateEmbedCode from '../views/Admin/CreateEmbedCode.vue';
import MiscAdmin from '../views/Admin/Misc.vue';
import TributeAdmin from '../views/Admin/TributeAdmin.vue';
import TributeVideoSongAnalytics from '../views/Admin/TributeVideoSongAnalytics.vue';

// =============================================================================
// SERVICE
// =============================================================================
import AnonymousStream from '../views/Services/AnonymousStream.vue';
import Billing from '../views/Services/Billing.vue';
import IFrameVideoPlayer from '../views/Services/IFrameVideoPlayer.vue';
import LiveDashboard from '../views/Services/LiveDashboard.vue';
import ManageServicesRefactor from '../views/Services/ManageServiceRefactored.vue';
import Renders from '../views/Services/Renders.vue';
import Services from '../views/Services/Services.vue';
import ServicesByHome from '../views/Services/ServicesByHome.vue';
import UploaderRefactored from '../views/Services/UploaderRefactored.vue';
import ViewStream from '../views/Services/ViewStream.vue';
import CreateService from '../views/Services/CreateService.vue';

import TributeVideoNew from '../views/Services/TributeVideo.vue';

// =============================================================================
// UTILITIES
// =============================================================================
import ConverterPdf from '../views/Utilities/ConverterPdf.vue';
import CreateUser from '../views/Utilities/CreateUser.vue';
import LogoutRefactored from '../views/Utilities/LogoutRefactored.vue';
import MigrationNotification from '../views/Utilities/MigrationNotification.vue';
import PathNotFound from '../views/Utilities/PathNotFound.vue';
import Settings from '../views/Utilities/Settings.vue';
import TermsAndConditions from '../views/Utilities/TermsAndConditions.vue';
import GoogleReport from '../views/Utilities/GoogleReport.vue';

// =============================================================================
// USERS
// =============================================================================
import Feedback from '../views/Users/Feedback.vue';
import ManageUsers from '../views/Users/ManageUsers.vue';
import Users from '../views/Users/Users.vue';

// =============================================================================
// OWNERS
// =============================================================================
import CreateOwner from '../views/Owners/CreateOwner.vue';
import ManageOwners from '../views/Owners/ManageOwners.vue';
import Owners from '../views/Owners/Owners.vue';
import OwnerSettings from '../views/Owners/OwnerSettings.vue';
import OwnerReport from '../views/Owners/OwnerReport.vue';

// =============================================================================
// HOMES
// =============================================================================
import CreateEditFuneralHome from '../views/FuneralHome/CreateEditFuneralHome.vue';
import HomeSignUp from '../views/FuneralHome/HomeSignUp.vue';

// =============================================================================
// DEBUGGING
// =============================================================================
import Test from '../views/Debugging/Test.vue';

// =============================================================================
// Ecommerce
// =============================================================================

import CreateEditSupplier from '@/views/Shop/CreateEditSupplier.vue';
import EcomAdmin from '@/views/Shop/EcomAdmin.vue';
import FuneralHomeEcom from '@/views/Shop/FuneralHomeEcom.vue';
import SupplierSettings from '@/views/Shop/SupplierSettings.vue';
import OrderDetails from '@/views/Shop/OrderDetails.vue';
import PurchaseDetails from '@/views/Shop/PurchaseDetails.vue';
import FuneralHomeShop from '@/views/Shop/FuneralHomeShop.vue';
import CreateEditProduct from '@/views/Shop/CreateEditProduct.vue';
import PurchaseStatus from '@/views/Shop/PurchaseStatus.vue';
import ShipmentDetails from '@/views/Shop/ShipmentDetails.vue';

// =============================================================================
// ROUTES
// =============================================================================
const routes = [
    {
        path: '/debugging/test',
        beforeEnter: authGuard,
        component: Test,
    },
    {
        path: '/',
        //Handle base redirect in authGuard to minimize nav redirect interuption
        // redirect: '/services',
        beforeEnter: authGuard,
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: authGuard,
        component: LogoutRefactored,
    },
    {
        path: '/services',
        name: 'Services',
        component: Services,
        beforeEnter: authGuard,
    },
    {
        path: '/tribute/:slug/:section?',
        name: 'TributeVideo',
        component: TributeVideoNew,
        beforeEnter: authGuard,
    },
    {
        path: '/tribute/family-share/:slug/:pin/:section?',
        name: 'TributeVideoFamily',
        component: TributeVideoNew,
        props: { requiredRoleLevel: 'TributeAdmin' }
    },
    {
        path: '/tribute/contributor/:slug/:pin/:section?',
        name: 'TributeVideoContributor',
        component: TributeVideoNew,
        props: { requiredRoleLevel: 'Tribute' }
    },
    {
        path: '/memoryshare/videoplayer/:slug',
        name: 'EmbedPlayer',
        component: IFrameVideoPlayer,
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions,
    },
    {
        path: '/services/create',
        name: 'CreateService',
        component: CreateService,
        beforeEnter: authGuard,
    },
    {
        path: '/services/homes/:funeral_home_id',
        name: 'ServicesByHome',
        component: ServicesByHome,
        beforeEnter: authGuard,
    },
    {
        path: '/services/:slug/add-pdf',
        name: 'AddPDF',
        component: ConverterPdf,
        beforeEnter: authGuard,
    },
    {
        path: '/services/:slug/billing',
        name: 'Billing',
        component: Billing,
        beforeEnter: authGuard,
    },
    {
        path: '/admin/settings',
        name: 'MiscAdminSettings',
        component: MiscAdmin,
        beforeEnter: authGuard,
    },

    {
        path: '/services/upload/:id',
        name: 'Uploader',
        component: UploaderRefactored,
        beforeEnter: authGuard,
    },
    {
        path: '/services/view/:slug',
        name: 'ViewStream',
        component: ViewStream,
    },
    {
        path: '/services/:slug/:event?',
        name: 'EditServices',
        component: ManageServicesRefactor,
        beforeEnter: authGuard,
    },
    {
        path: '/services/remote/:slug/:token',
        name: 'AnonymousStream',
        component: AnonymousStream,
    },
    {
        path: '/view-story/:slug',
        name: 'ViewStory',
        component: ViewStory,
    },
    {
        path: '/view-story-qr/:slug',
        name: 'ViewStoryQr',
        component: ViewStoryQr,
    },
    {
        path: '/analytics',
        name: 'Analytics',
        component: Analytics,
        beforeEnter: authGuard,
    },
    {
        path: '/analytics/:slug',
        name: 'ServiceAnalytics',
        component: Analytics,
        beforeEnter: authGuard,
    },
    {
        path: '/homes',
        name: 'Homes',
        component: HomesList,
        meta: { admin: true, owner: true },
        beforeEnter: authGuard,
    },
    {
        path: '/google-report/:funeralHomeId',
        name: 'GoogleReport',
        component: GoogleReport,
        beforeEnter: authGuard,
    },
    {
        path: '/homes/:owner_id',
        name: 'HomesByOwner',
        component: HomesByOwner,
        meta: { admin: true, owner: true },
        beforeEnter: authGuard,
    },
    {
        path: '/landing-page/:id',
        name: 'LandingPage',
        component: LandingPage,
    },
    {
        path: '/tribute-upload/:slug/:pin',
        name: 'TributeUploadPage',
        component: AnonTributePage,
    },
    {
        path: '/tribute-upload-edit/:slug/:pin',
        name: 'TributeFamilyPage',
        component: AnonTributePage,
    },
    {
        path: '/tribute-story-upload/:slug',
        name: 'TributeStoryUpload',
        component: TributeStoriesContribute,
    },
    {
        path: '/signup',
        name: 'Signup',
        component: HomeSignUp,
    },
    {
        path: '/daily-stream-sheet',
        name: 'DailyStreamSheet',
        component: DailyStreamSheet,
        meta: { admin: true, owner: true },
        beforeEnter: authGuard,
    },
    {
        path: '/owners',
        name: 'Owners',
        component: Owners,
        meta: { admin: true },
        beforeEnter: authGuard,
    },
    {
        path: '/owner-report/:id',
        name: 'OwnerReport',
        component: OwnerReport,
        meta: { admin: true, owner: true },
        beforeEnter: authGuard,
    },
    {
        path: '/renders',
        name: 'Renders',
        component: Renders,
        meta: { admin: true },
        beforeEnter: authGuard,
    },
    {
        path: '/manage-homes/:id',
        name: 'EditHomes',
        component: CreateEditFuneralHome,
        meta: { admin: true },
        beforeEnter: authGuard,
    },
    {
        path: '/homes/create/:ownerId?',
        name: 'CreateHome',
        component: CreateEditFuneralHome,
        meta: { admin: true, owner: true },
        beforeEnter: authGuard,
    },

    {
        path: '/owners/create',
        name: 'CreateOwner',
        component: CreateOwner,
        beforeEnter: authGuard,
    },
    {
        path: '/owners/settings/:id',
        name: 'EditOwner',
        component: ManageOwners,
        meta: { admin: true },
        beforeEnter: authGuard,
    },

    {
        path: '/admins',
        name: 'Admins',
        component: Users,
        beforeEnter: authGuard,
    },
    {
        path: '/admins/billing-events',
        name: 'AdminBilling',
        component: AdminBilling,
        meta: { admin: true },
        beforeEnter: authGuard,
    },
    {
        path: '/manage-users/create',
        name: 'ManageUsers',
        component: ManageUsers,
        meta: { admin: true },
        beforeEnter: authGuard,
    },
    {
        path: '/admins/create',
        name: 'ManageAdminUsers',
        component: ManageAdminUsers,
        meta: { admin: true },
        beforeEnter: authGuard,
    },

    {
        path: '/admins/edit/:id',
        name: 'EditAdminUsers',
        component: ManageAdminUsers,
        meta: { admin: true, owner: true },
        beforeEnter: authGuard,
    },
    {
        path: '/manage-users/edit/:id',
        name: 'EditUsers',
        component: ManageUsers,
        meta: { admin: true },
        beforeEnter: authGuard,
    },
    {
        path: '/accept',
        name: 'CreateUser',
        component: CreateUser,
        meta: { admin: true },
        beforeEnter: authGuard,
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter: authGuard,
    },

    {
        path: '/feedback',
        name: 'Feedback',
        component: Feedback,
        beforeEnter: authGuard,
    },
    {
        path: '/homes/settings/:id',
        name: 'AdminSettings',
        component: AdminSettings,
        meta: { admin: true, owner: true },
        beforeEnter: authGuard,
    },
    {
        path: '/owner-settings/:id',
        name: 'OwnerSettings',
        component: OwnerSettings,
        meta: { admin: true, owner: true },
        beforeEnter: authGuard,
    },
    {
        path: '/ecom',
        name: 'EcomAdmin',
        component: EcomAdmin,
        beforeEnter: authGuard,
        meta: { admin: true },
    },
    {
        path: '/orders',
        name: 'FuneralHomeEcom',
        component: FuneralHomeEcom,
        beforeEnter: authGuard,
    },
    {
        path: '/tribute',
        name: 'TributeAdmin',
        component: TributeAdmin,
        beforeEnter: authGuard,
        meta: { admin: true },
    },
    {
        path: '/song/:id',
        name: 'TributeVideoSongAnalytics',
        component: TributeVideoSongAnalytics,
        beforeEnter: authGuard,
        meta: { admin: true },
    },
    {
        path: '/shop-home/',
        name: 'FuneralHomeShop',
        component: FuneralHomeShop,
        beforeEnter: authGuard,
    },
    {
        path: '/order-details/:id',
        name: 'OrderDetails',
        component: OrderDetails,
        beforeEnter: authGuard,
    },
    {
        path: '/purchase-details/:id',
        name: 'PurchaseDetails',
        component: PurchaseDetails,
        beforeEnter: authGuard,
    },

    {
        path: '/shipment/:id',
        name: 'ShipmentDetails',
        component: ShipmentDetails,
        beforeEnter: authGuard,
    },
    {
        path: '/purchase-status/:slug',
        name: 'PurchaseStatus',
        component: PurchaseStatus,
    },
    {
        path: '/suppliers/edit/:id',
        name: 'EditSupplier',
        component: CreateEditSupplier,
        beforeEnter: authGuard,
    },
    {
        path: '/suppliers/create',
        name: 'CreateSupplier',
        component: CreateEditSupplier,
        beforeEnter: authGuard,
    },
    {
        path: '/supplier-settings/:supplier_id',
        name: 'SupplierSettings',
        component: SupplierSettings,
        beforeEnter: authGuard,
    },
    {
        path: '/products/create/:supplier_id',
        name: 'CreateProduct',
        component: CreateEditProduct,
        beforeEnter: authGuard,
    },
    {
        path: '/products/edit/:supplier_id/:product_id',
        name: 'EditProduct',
        component: CreateEditProduct,
        beforeEnter: authGuard,
    },
    {
        path: '/dashboard/live',
        name: 'LiveDashboard',
        component: LiveDashboard,
        beforeEnter: authGuard,
    },
    {
        path: '/migration-notification',
        name: 'MigrationNotification',
        component: MigrationNotification,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PathNotFound',
        component: PathNotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
