<template>
    <div v-if="$auth.role.includes('SuperAdmin')">
        <v-menu min-width="250" offset-y v-model="menuOpen">
            <template #activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" outlined class="d-flex align-center">
                    <span> Admin </span>
                    <font-awesome-icon class="ml-2" icon="fa-regular fa-wrench"></font-awesome-icon>
                </v-btn>
            </template>
            <v-card>
                <v-list>
                    <v-list-item>
                        <v-btn block depressed color="#ff530d" dark @click="openPanel('tributeSuper')">Tribute</v-btn>
                    </v-list-item>
                    <v-list-item v-if="standardRenderId">
                        <v-btn block depressed color="#ff530d" dark @click="openPanel('standardRenderSuper')"
                            >Original Render</v-btn
                        >
                    </v-list-item>

                    <v-list-item v-if="publicRenderId">
                        <v-btn block depressed color="#ff530d" dark @click="openPanel('publicRenderSuper')"
                            >Public Render</v-btn
                        >
                    </v-list-item>
                    <v-list-item>
                        <v-btn block depressed color="#ff530d" dark @click="openPanel('changelogSuper')"
                            >Changelog</v-btn
                        >
                    </v-list-item>
                    <!-- <v-list-item>
                        <v-btn block depressed color="#ff530d" dark @click="openPanel('tributeSuper')">Tribute</v-btn>
                    </v-list-item> -->
                </v-list>
            </v-card>
        </v-menu>

        <TributePanelSuper ref="tributeSuper" v-if="tributeId" :eventId="tributeEventId" />
        <ChangelogPanel
            ref="changelogSuper"
            v-if="tributeId"
            :tributeVideoId="tributeId"
            @init-rerender="$emit('init-rerender')"
        />
        <TributeRenderPanelSuper
            ref="standardRenderSuper"
            v-if="standardRenderId"
            :tributeRenderId="standardRenderId"
        />
        <TributeRenderPanelSuper ref="publicRenderSuper" v-if="publicRenderId" :tributeRenderId="publicRenderId" />
    </div>
</template>
<script>
import TributePanelSuper from '../AdminPanels/TributePanelSuper.vue';
import ChangelogPanel from '@/components/Tribute/Layout/ChangelogPanel.vue';
import TributeRenderPanelSuper from '../AdminPanels/TributeRenderPanelSuper.vue';
export default {
    data() {
        return {
            menuOpen: false,
        };
    },
    inject: ['tributeRender'],
    components: {
        TributePanelSuper,
        ChangelogPanel,
        TributeRenderPanelSuper,
    },
    computed: {
        tributeId() {
            return this.$store.state.tributeVideo.id;
        },
        tributeEventId() {
            return this.$store.state.tributeVideo.eventId;
        },
        standardRenderId() {
            return this.tributeRender?.standardRender?.id || null;
        },
        publicRenderId() {
            return this.tributeRender?.publicRender?.id || null;
        },
    },
    methods: {
        openPanel(panel) {
            const ref = this.$refs[panel];

            if (ref && typeof ref.togglePanel === 'function') {
                ref.togglePanel();
            }
        },
    },
};
</script>
<style lang=""></style>
