<template>
    <div>
        <RightPanelModal v-model="showPanel">
            <template #title>
                <div style="width: 100%" class="d-flex align-center justify-space-between">
                    <span class="pa-3 pb-0"> Tribute Render Super </span>
                    <v-btn @click="togglePanel" fab small depressed>
                        <font-awesome-icon icon="fa-regular fa-x"></font-awesome-icon>
                    </v-btn>
                </div>
            </template>

            <template #content>
                <v-card-text>
                    <v-row v-if="formData">
                        <v-col cols="12" md="6">
                            <v-text-field label="Id" v-model="formData.id"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="EventId" v-model="formData.eventId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Service Id" v-model="formData.serviceId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Funeral Home Id" v-model="formData.funeralHomeId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Tribute Video Id" v-model="formData.tributeVideoId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Owner Id" v-model="formData.ownerId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <calendar datetime v-model="formData.createDate" label="Create Date" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <calendar datetime v-model="formData.updateDate" label="Update Date" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="CreatomateId" v-model="formData.creatomateId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="HLS Guid" v-model="formData.hlsGuid"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="hlsStatuses"
                                item-text="label"
                                item-value="value"
                                label="HLS Status"
                                v-model="formData.hlsStatus"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="HLS URL" v-model="formData.hlsUrl"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="tributeRenderTypes"
                                item-text="label"
                                item-value="value"
                                label="Render Type"
                                v-model="formData.renderType"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-select
                                :items="creatomateRenderStatuses"
                                item-text="label"
                                item-value="value"
                                label="Status"
                                v-model="formData.status"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :items="storageTypes"
                                item-text="label"
                                item-value="value"
                                label="Storage Type"
                                v-model="formData.storageType"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Title" v-model="formData.title"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field label="URL" v-model="formData.url"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-textarea label="Creatomate Error" v-model="formData.creatomateErrorMessage"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>

            <template #actions>
                <div class="p-3 pt-0 d-flex flex-wrap" style="width: 100%; gap: 12px">
                    <div class="flex-grow-1">
                        <v-btn outlined depressed block @click="togglePanel"> Close</v-btn>
                    </div>
                    <div class="flex-grow-1">
                        <v-btn
                            @click="updateTributeRender"
                            :disabled="loading"
                            :loading="loading"
                            depressed
                            color="#ff530d"
                            block
                            :dark="!loading"
                            >Save</v-btn
                        >
                    </div>
                </div>
            </template>
        </RightPanelModal>

        <slot name="activator" :on-click="togglePanel"> </slot>
    </div>
</template>
<script>
import RightPanelModal from '@/components/Tribute/Layout/RightPanelModal.vue';
import TributeVideoService from '@/services/tributeVideo.service';
import Calendar from '../../ui/Calendar.vue';
import { tributeRenderTypes, storageTypes, hlsStatuses, creatomateRenderStatuses } from '../../../constants';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            storageTypes,
            hlsStatuses,
            creatomateRenderStatuses,
            tributeRenderTypes,
            tributeApi: null,
            loading: false,
            formData: null,
            showPanel: false,
        };
    },
    components: { RightPanelModal, Calendar },
    props: {
        tributeRenderId: {
            type: Number,
            required: true,
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        togglePanel() {
            this.showPanel = !this.showPanel;
        },
        parseDate(date) {
            if (date) {
                const localDate = this.$moment.utc(date).local();
                return localDate.toISOString();
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async getTributeRender(id) {
            try {
                this.loading = true;
                const resp = await this.tributeApi.adminGetTributeRender(id);
                if (resp.data) {
                    this.formData = { ...resp.data };
                    this.formData.createDate = this.parseDate(this.formData.createDate);
                    this.formData.updateDate = this.parseDate(this.formData.updateDate);
                }
            } catch (error) {
                console.log(error, 'error fetching tribute render');
            } finally {
                this.loading = false;
            }
        },
        async updateTributeRender() {
            try {
                this.loading = true;
                await this.tributeApi.adminUpdateTributeRender(this.tributeRenderId, this.formData);
                this.showPanel = false;
                this.showSnackbar({ message: 'Tribute render updated' });
            } catch (error) {
                this.showSnackbar({ message: 'Error updating tribute render', color: 'error' });
            } finally {
                this.loading = false;
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.tributeApi = TributeVideoService(this.token);
        this.getTributeRender(this.tributeRenderId);
    },
};
</script>
<style lang=""></style>
