import ApiService from './api.service';

const TributeVideoService = token => {
    const api = ApiService.createApiInstance(token);

    return {
        createTributeVideoByEvent(eventId, data) {
            if (!eventId || eventId <= 0) {
                throw new Error('Invalid value for eventId');
            }

            return api.post(`/TributeVideo/event/${eventId}`, data);
        },
        createTributeVideoByService(serviceSlug, data) {
            if (!serviceSlug) {
                throw new Error('Invalid value for serviceSlug');
            }

            return api.post(`/TributeVideo/service/${serviceSlug}`, data);
        },
        getTributeVideoByEvent(eventId) {
            return api.get(`/TributeVideo/get-tribute-by-event/${eventId}`);
        },
        getTributeVideoByServiceSlug(serviceSlug) {
            return api.get(`/TributeVideo/get-tribute-by-service/${serviceSlug}`);
        },
        submitRender(tributeVideoId) {
            let data = {
                tributeId: tributeVideoId,
            };

            return api.post(`/TributeVideoRender/`, data);
        },
        adminUpdateTribute(id, dto) {
            return api.put(`/TributeVideo/tribute-admin-update/${id}`, dto);
        },
        getSharedUploadInfo(slug) {
            return api.get(`/TributeVideo/shared-upload-info/${slug}`);
        },
        getTributeToken(slug, pin) {
            return api.get(`/TributeVideo/tribute-token/${slug}/${pin}`);
        },
        invite(tributeId, data) {
            return api.post(`/TributeVideoPhoto/tribute-invite/${tributeId}`, data);
        },
        shareFinalVideo(tributeId, data) {
            return api.post(`/TributeVideo/video-share/${tributeId}`, data);
        },
        refreshTokenAndAllLinks(tributeId) {
            return api.post(`TributeVideo/refresh-token-and-all-links/${tributeId}`);
        },
        getCurrentServiceToken(tributeId) {
            return api.get(`TributeVideo/current-service-tribute-token/${tributeId}`);
        },
        generatePreviewJson(tributeId) {
            return api.post(`/TributeVideoRender/generate-tribute-video-json/${tributeId}`);
        },
        forceRefreshTributeJson(tributeId) {
            return api.post(`/TributeVideoRender/force-refresh-tribute-video-json/${tributeId}`);
        },
        getTributRender(tributeId, copyrightSafe = false) {
            return api.get(`/TributeVideoRender/${tributeId}?copyrightSafe=${copyrightSafe}`);
        },
        getSettings(tributeId) {
            return api.get(`/TributeVideo/settings/${tributeId}`);
        },
        updateDeadline(tributeId, data) {
            return api.post(`/TributeVideo/deadline/${tributeId}`, data);
        },
        getChangelogEvents(tributeId, pageSize = 24, continuationToken = null) {
            let params = {
                continuationToken: continuationToken,
                pageSize: pageSize,
            };

            return api.get(`/TributeVideo/changelog/${tributeId}`, { params });
        },
        checkOutOfSync(tributeRenderId) {
            return api.get(`/TributeVideoRender/check-out-of-sync/${tributeRenderId}`);
        },
        undoAllChangesSinceRender(tributeId) {
            return api.post(`/TributeVideo/changelog/undo-all-since-render/${tributeId}`);
        },
        adminGetTributeRender(renderId) {
            return api.get(`/TributeVideoRender/admin-edit/${renderId}`);
        },
        adminUpdateTributeRender(renderId, data) {
            return api.put(`/TributeVideoRender/admin-edit/${renderId}`, data);
        },
    };
};

export default TributeVideoService;
