export default {
    /**
     *Handles different types of  click events for multi select
     * @param {Event} event - The mouse event.
     * @param {Object} item - The clicked item
     * @param {Object} options - Config options for handling click
     * @returns {Object} - The updated selected items and  type of action.
     */
    handleClick(event, item, options) {
        const { selectedItems, allItems, selectRangeStart } = options;

        if (event.shiftKey) {
            return handleShiftClick(item, selectedItems, allItems, selectRangeStart);
        } else if (event.ctrlKey || event.metaKey) {
            return handleCtrlClick(item, selectedItems, allItems);
        } else {
            return handleSingleClick(item, selectedItems, allItems, selectRangeStart);
        }
    },

    /**
     *Handles different types of  keydown events for multi select
     * @param {Event} event - The keyboard event.
     * @param {Object} options - Config options for handling keydown
     * @returns {Object} - The updated selected items and  type of action.
     */
    async handleKeydown(event, options) {
        const {
            selectedItems,
            allItems,
            selectRangeStart,
            containerWidth,
            itemWidth,
            fetchAllItemsCallback,
            deleteItemsCallback,
        } = options;

        const key = event.key;
        const supportedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'a', 'Escape', 'Delete'];

        if (!supportedKeys.includes(key)) {
            return { type: 'none', selectedItems };
        }

        switch (key) {
            case 'ArrowLeft':
                return handleArrowLeft(selectedItems, allItems, event.shiftKey, selectRangeStart);
            case 'ArrowRight':
                return handleArrowRight(selectedItems, allItems, event.shiftKey, selectRangeStart);
            case 'ArrowUp':
                handleArrowUp(selectedItems, allItems, event.shiftKey, selectRangeStart, containerWidth, itemWidth);
            case 'ArrowDown':
                return handleArrowDown(
                    selectedItems,
                    allItems,
                    event.shiftKey,
                    selectRangeStart,
                    containerWidth,
                    itemWidth,
                );
            case 'a':
                if (!(event.ctrlKey || event.metaKey) || !fetchAllItemsCallback) {
                    return { type: 'none', selectedItems };
                }

                return handleSelectAll(selectedItems, fetchAllItemsCallback);
            case 'Escape':
                return handleDeselectAll();
            case 'Delete':
                return handleDeleteSelectedItems(deleteItemsCallback);
            default:
                return { type: 'none', selectedItems };
        }
    },
};

function handleShiftClick(item, selectedItems, allItems, selectRangeStart) {
    if (!item || !allItems || allItems.length === 0) {
        return { type: 'none', selectedItems }; // Return the existing selection if inputs are invalid
    }

    // const startItem = selectedItems.length > 0 ? selectedItems[0] : null;
    if (selectRangeStart === null) {
        selectRangeStart = allItems.indexOf(item);
        return { type: 'single-select', selectedItems: [item], selectRangeStart };
    }

    // const startIndex = startItem ? allItems.indexOf(startItem) : -1;
    const endIndex = allItems.indexOf(item);

    if (selectRangeStart === -1 || endIndex === -1) {
        // Reset the startIndex if indices are invalid
        return { type: 'single-select', selectedItems: [item], selectRangeStart: null };
    }

    // Calculate the range and update selected items
    const rangeStart = Math.min(selectRangeStart, endIndex);
    const rangeEnd = Math.max(selectRangeStart, endIndex);

    const newSelectedItems = allItems.slice(rangeStart, rangeEnd + 1);

    return { type: 'range-select', selectedItems: newSelectedItems, selectRangeStart };
}

function handleCtrlClick(item, selectedItems, allItems) {
    return toggleSelected(item, selectedItems, allItems);
}

function handleSingleClick(item, selectedItems, allItems, selectRangeStart) {
    if (selectedItems.some(x => x.id === item.id)) {
        return { type: 'toggle', selectedItems: [], selectRangeStart };
    } else {
        selectRangeStart = allItems.indexOf(item);
        return { type: 'toggle', selectedItems: [item], selectRangeStart };
    }
}

function toggleSelected(item, selectedItems, allItems) {
    if (!item || !item.id) return;

    const index = selectedItems.findIndex(x => x.id === item.id);

    if (index === -1) {
        const selectRangeStart = allItems.indexOf(item);
        return { type: 'toggle', selectedItems: [...selectedItems, item], selectRangeStart };
    } else {
        return { type: 'toggle', selectedItems: selectedItems.filter(x => x.id !== item.id), selectRangeStart: null };
    }
}

function handleArrowLeft(selectedItems, allItems, isShiftKey, selectRangeStart) {
    if (!allItems || allItems.length === 0) {
        return { type: 'none', selectedItems };
    }

    let currentIndex = selectedItems.length ? allItems.indexOf(selectedItems[selectedItems.length - 1]) : -1;

    if (currentIndex < 0 || currentIndex <= selectRangeStart) {
        currentIndex = selectedItems.length ? allItems.indexOf(selectedItems[0]) : -1;
    }

    if (currentIndex < 0) {
        return { type: 'none', selectedItems };
    }

    const nextIndex = currentIndex - 1;
    const nextItem = allItems[nextIndex];

    if (nextIndex < 0) {
        return { type: 'none', selectedItems };
    }

    if (isShiftKey) {
        return handleShiftClick(nextItem, selectedItems, allItems, selectRangeStart);
    }

    return { type: 'navigate', selectedItems: [nextItem], selectRangeStart: nextIndex };
}

function handleArrowRight(selectedItems, allItems, isShiftKey, selectRangeStart) {
    if (!allItems || allItems.length === 0) {
        return { type: 'none', selectedItems };
    }

    let currentIndex = selectedItems.length ? allItems.indexOf(selectedItems[selectedItems.length - 1]) : -1;

    if (currentIndex <= selectRangeStart) {
        currentIndex = selectedItems.length ? allItems.indexOf(selectedItems[0]) : -1;
    }

    const nextIndex = currentIndex + 1;

    if (currentIndex >= allItems.length - 1) {
        return { type: 'none', selectedItems };
    }

    const nextItem = allItems[nextIndex];

    if (isShiftKey) {
        return handleShiftClick(nextItem, selectedItems, allItems, selectRangeStart);
    }

    return { type: 'navigate', selectedItems: [nextItem], selectRangeStart: nextIndex };
}

function handleArrowUp(selectedItems, allItems, isShiftKey, selectRangeStart, containerWidth, itemWidth) {
    const itemsPerRow = Math.floor(containerWidth / itemWidth);

    if (!allItems || allItems.length === 0 || !selectedItems || selectedItems.length === 0) {
        return { type: 'none', selectedItems };
    }

    let currentIndex = selectedItems.length ? allItems.indexOf(selectedItems[selectedItems.length - 1]) : -1;

    if (currentIndex <= selectRangeStart) {
        currentIndex = selectedItems.length ? allItems.indexOf(selectedItems[0]) : -1;
    }

    const newIndex = currentIndex - itemsPerRow;
    const cappedIndex = Math.max(newIndex, 0);

    const nextItem = allItems[cappedIndex];

    if (isShiftKey) {
        return handleShiftClick(nextItem, selectedItems, allItems, selectRangeStart);
    }

    return { type: 'navigate', selectedItems: [nextItem], selectRangeStart: cappedIndex };
}

function handleArrowDown(selectedItems, allItems, isShiftKey, selectRangeStart, containerWidth, itemWidth) {
    const itemsPerRow = Math.floor(containerWidth / itemWidth);

    if (!allItems || allItems.length === 0 || !selectedItems || selectedItems.length === 0) {
        return { type: 'none', selectedItems };
    }

    let currentIndex = selectedItems.length ? allItems.indexOf(selectedItems[selectedItems.length - 1]) : -1;

    if (currentIndex <= selectRangeStart) {
        currentIndex = selectedItems.length ? allItems.indexOf(selectedItems[0]) : -1;
    }

    const newIndex = currentIndex + itemsPerRow;
    const cappedIndex = Math.min(newIndex, allItems.length - 1);

    const nextItem = allItems[cappedIndex];

    if (isShiftKey) {
        return handleShiftClick(nextItem, selectedItems, allItems, selectRangeStart);
    }

    return { type: 'navigate', selectedItems: [nextItem], selectRangeStart: cappedIndex };
}

async function handleSelectAll(selectedItems, fetchAllItemsCallback) {
    if (typeof fetchAllItemsCallback !== 'function') {
        return { type: 'none', selectedItems };
    }

    try {
        const fetchedItems = await fetchAllItemsCallback();
        return { type: 'select-all', selectedItems: fetchedItems };
    } catch (error) {
        console.error('Error during fetch: ', error);
        return { type: 'none', selectedItems };
    }
}

function handleDeselectAll() {
    return { type: 'deselect-all', selectedItems: [] };
}

function handleDeleteSelectedItems(deleteItemsCallback) {
    deleteItemsCallback();
}
