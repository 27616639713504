<template>
    <div class="live-preview-component-container">
        <div class="preview-container" :class="loading ? 'hidden-preview' : ''" ref="previewContainer"></div>
        <div class="preview-overlay" :class="loading ? '' : 'hidden-preview'">
            <div v-if="loadError" class="d-flex flex-column align-items-center">
                <font-awesome-icon style="font-size: 2rem" icon="fa-regular fa-cloud-exclamation"></font-awesome-icon>
                <p class="mt-2">Error loading preview.</p>
            </div>
            <div v-else-if="!currentSource" class="d-flex flex-column align-items-center">
                <font-awesome-icon style="font-size: 2rem" icon="fa-regular fa-cloud-exclamation"></font-awesome-icon>
                <p class="mt-2">Preview source not found.</p>
            </div>
            <div v-else class="d-flex flex-column align-items-center">
                <v-progress-circular indeterminate></v-progress-circular>
                <p class="mt-2">Loading preview...</p>
            </div>
        </div>
        <div v-if="!loading" class="preview-controls">
            <button v-if="!isPlaying" @click="playVideo">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.19019 5.87695V20.5734L20.7374 13.2252L9.19019 5.87695Z" fill="white" />
                </svg>
            </button>

            <button v-else @click="pauseVideo">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="7" y="5" width="4" height="16" fill="white" />
                    <rect x="15" y="5" width="4" height="16" fill="white" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import { Preview } from '@creatomate/preview';

export default {
    data() {
        return {
            preview: null,
            loading: true,
            loadError: false,
            isPlaying: false,
            source: this.currentSource,
            previewFont: this.customFont
        };
    },
    props: {
        currentSource: {
            type: Object,
            default: false,
        },
        FirstName: {
            type: String,
            required: true,
        },
        LastName: {
            type: String,
            required: true,
        },
        BirthDate: {
            type: String,
            required: true,
        },
        DeathDate: {
            type: String,
            required: true,
        },
        customFont: {
            type: Object,
            required: false,
        },
        photo: {
            type: String,
            required: false,
        },
    },
    watch: {
        async FirstName(newValue) {
            if (this.loading === true) return;
            this.isPlaying = false;

            if (this.preview) {
                await this.preview.applyModifications({
                    FirstName: newValue,
                });
                await this.ensureElementVisibility(this.preview, 'DeathDate', 1.5);
            }
        },
        async LastName(newValue) {
            if (this.loading === true) return;
            this.isPlaying = false;


            if (this.preview) {
                await this.preview.applyModifications({
                    LastName: newValue,
                });
                await this.ensureElementVisibility(this.preview, 'DeathDate', 1.5);
            }
        },
        async BirthDate(newValue) {
            if (this.loading === true) return;
            this.isPlaying = false;


            if (this.preview) {
                await this.preview.applyModifications({
                    BirthDate: newValue,
                });
                await this.ensureElementVisibility(this.preview, 'DeathDate', 1.5);
            }
        },
        async DeathDate(newValue) {
            if (this.loading === true) return;
            this.isPlaying = false;

            if (this.preview) {
                await this.preview.applyModifications({
                    DeathDate: newValue,
                });
                await this.ensureElementVisibility(this.preview, 'DeathDate', 1.5);
            }
        },
        async customFont(newValue) {
            if (this.loading) return;
            this.loading = true;
            this.isPlaying = false;

            this.previewFont = newValue;

            let sourceCopy = JSON.parse(JSON.stringify(this.source));

            if (!sourceCopy || !sourceCopy.elements) return;

            for (const el of sourceCopy.elements[0].elements) {
                if (el.type === 'text' && typeof el.name === 'string' && el.name.trim().toLowerCase() !== 'dash') {
                    el.font_family = newValue.family;
                }
                if (el.name === 'FirstName') el.text = this.$props.FirstName;
                if (el.name === 'LastName') el.text = this.$props.LastName;
                if( el.name === 'FullName') el.text = `${this.$props.FirstName} ${this.$props.LastName}`;
                if (el.name === 'BirthDate') el.text = this.$props.BirthDate;
                if (el.name === 'DeathDate') el.text = this.$props.DeathDate;
            }

            await this.preview.setSource(sourceCopy);
            await this.preview.applyModifications({
                MainPicture: this.$props.photo,
                MainPictire: this.$props.photo, //Typo in some creatomate templates
            });

            await this.ensureElementVisibility(this.preview, 'DeathDate', 1.5);
            await this.preview.play();

            this.loading = false;
            this.isPlaying = true;
        }
    },
    async mounted() {
        if (this.$props.currentSource) {
            const el = this.$refs.previewContainer;

            if (el) {
                const preview = new Preview(el, 'interactive', process.env.VUE_APP_CREATOMATE_KEY);
                this.preview = preview;

                preview.onReady = async () => {
                    try {

                        let sourceCopy = JSON.parse(JSON.stringify(this.$props.currentSource));

                        for (const el of sourceCopy.elements[0].elements) {
                            if (el.type === 'text' && typeof el.name === 'string' && el.name.trim().toLowerCase() !== 'dash') {
                                el.font_family = this.$props.customFont.family;
                            }
                            if (el.name === 'FirstName') el.text = this.$props.FirstName;
                            if (el.name === 'LastName') el.text = this.$props.LastName;
                            if( el.name === 'FullName') el.text = `${this.$props.FirstName} ${this.$props.LastName}`;
                            if (el.name === 'BirthDate') el.text = this.$props.BirthDate;
                            if (el.name === 'DeathDate') el.text = this.$props.DeathDate;
                        }

                        await preview.setSource(sourceCopy);

                    } catch (error) {
                        this.loadError = true;
                    } finally {

                        const initialZoom = this.calculateZoom(el.clientWidth);

                        await Promise.all([
                            this.preview.setZoom('fixed', initialZoom),
                            this.preview.applyModifications({
                                MainPicture: this.$props.photo,
                                MainPictire: this.$props.photo, //Typo in some creatomate templates
                            }),
                        ]);

                        await this.preview.play();
                        this.isPlaying = true;
                        this.loading = false;
                    }
                };

                this.resizeObserver = new ResizeObserver(entries => {
                    for (let entry of entries) {
                        if (!this.loading) {
                            const newZoom = this.calculateZoom(entry.contentRect.width);
                            this.preview.setZoom('fixed', newZoom);
                        }
                    }
                });

                this.resizeObserver.observe(el);

            }
        }
        return;
    },
    methods: {
        async playVideo() {
            this.isPlaying = true;
            if (this.preview) {
                await this.preview.play();
            }
        },
        async pauseVideo() {
            this.isPlaying = false;
            if (this.preview) {
                await this.preview.pause();
            }
        },
        async ensureElementVisibility(preview, elementName, addTime) {
            const element = preview.getElements().find((element) => element.source.name === elementName);
            if (element) {
                await preview.setTime(element.globalTime + addTime);
            }
        },
        calculateZoom(clientWidth) {
            if (clientWidth >= 670) {
                return 0.55 + ((clientWidth - 670) / (740 - 670)) * (0.6 - 0.55);
            } else if (clientWidth >= 510) {
                return 0.5 + ((clientWidth - 510) / (670 - 510)) * (0.55 - 0.5);
            } else if (clientWidth >= 460) {
                return 0.4 + ((clientWidth - 460) / (510 - 460)) * (0.5 - 0.4);
            } else if (clientWidth >= 410) {
                return 0.35 + ((clientWidth - 410) / (460 - 410)) * (0.4 - 0.35);
            } else if (clientWidth >= 340) {
                return 0.3 + ((clientWidth - 330) / (410 - 340)) * (0.35 - 0.3);
            } else {
                return 0.3;
            }
        },
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }
};
</script>

<style lang="scss">
.live-preview-component-container {
    position: relative;
    aspect-ratio: 16/9;
    background-color: black;

    .hidden-preview {
        opacity: 0;
        z-index: -2;
    }
}

.preview-container {
    aspect-ratio: 16/9;
    opacity: 1;
    transition: 0.5s;
}

.preview-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #00000000;
    color: white;
}

.preview-controls {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 10;
    display: flex;
    align-items: center;
    pointer-events: auto;

    button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        width: 28px;
        height: 28px;
        fill: white;
        pointer-events: auto;
    }
}
</style>
